import { NEW_LINE } from 'modals/chart-details/charts-query-engine/characters';

//////////////////////////////////////////////
// returns: "{{value}} AS {{alias}}"
// returns: "{{value}} AS ({{alias}})"
// e.g. "round(available_spaces) AS available_spaces"
//////////////////////////////////////////////
export default function createAlias({ text, alias }, options = {}) {
  const { enable = true, includeParens, newLine: _newLine } = options;
  if (!enable || !text) return;
  const newLine = _newLine ? NEW_LINE : '';
  if (!alias) return text;
  if (text === alias) return text;
  const aliasString = includeParens ? `(${newLine}${alias}${newLine})` : alias;
  return `${text} AS ${aliasString}`;
}
