import { api, q } from 'config/api';

export default function useChartTableMetrics(overrides) {
  return q.useQuery({
    queryKey: ['chartTableMetrics'],
    queryFn: () =>
      api(
        `query chartTableMetrics {
          chartTableMetrics {
            id
            name
            db
            table
          }
        }`,
      ),
    ...overrides,
  });
}
