import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '@optra/kit';

import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import { api, q, useOnSuccess } from 'config/api';
import { useWorkflowAction } from 'queries';

export default function FirebasePushNotificationActionForm({ workflowActionId, onClose }) {
  const { handleSubmit: onSubmit, reset } = useForm({
    defaultValues: {
      configuration: {},
    },
  });
  const [error, setError] = useState();

  const {
    data,
    isLoading: fetching,
    isSuccess,
    error: fetchError,
  } = useWorkflowAction(workflowActionId);
  const qc = q.useQueryClient();
  const updateWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation updateWorkflowActionFirebase ($form: updateWorkflowActionForm!) {
          workflowAction: updateWorkflowAction(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      onClose();
    },
    onError(error) {
      setError(error);
    },
  });

  useOnSuccess(
    () => {
      reset({
        configuration: {},
      });
    },
    { isSuccess },
    [data, reset],
  );

  const handleSubmit = onSubmit(form => {
    setError(null);

    let configuration = {};
    form?.configurationFields?.forEach(configurationField => {
      configuration[configurationField?.id] = {
        disabled: !configurationField?.enabled,
      };
    });

    updateWorkflowAction.mutate({
      id: workflowActionId,
      configuration,
    });
  });

  const loading = fetchError || fetching || updateWorkflowAction.isPending;

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Save Action">
            {error.message}
          </Message>
        )}
        {fetchError && (
          <Message variant="danger" title="Couldn't Load Action">
            {fetchError.message}
          </Message>
        )}
      </ModalBody>
      <ModalFooter>
        <Button size="xl" loading={loading} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
