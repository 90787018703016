import { Draggable } from 'react-beautiful-dnd';

import { Group } from '@optra/kit';

import { useChartCtx } from '../context';

import { AddItemDropdown, DraggableItem, DroppableZone } from './fragments';
import VariableSelector from './variable-selector';

///////////////////////
// X-AXIS
///////////////////////

export default function ControlCategories(props) {
  const {
    heading = 'Categories',
    description = 'Select the variables that make up the x-axis',
    collapsible = true,
    scrollable = false,
    defaultCollapsed = false,
    ...rest
  } = props;
  const {
    actions: { replaceVariable, removeVariable },
    helpers: { getChartConfig },
  } = useChartCtx();

  const { xAxis } = getChartConfig();

  const hasVariables = !!xAxis?.length;

  const field = 'xAxis';
  const count = xAxis?.length;

  return (
    <Group
      heading={heading}
      description={description}
      count={count}
      collapsible={collapsible}
      scrollable={scrollable}
      defaultCollapsed={defaultCollapsed}
      {...rest}
    >
      <DroppableZone droppableId={field}>
        {({ placeholder, ...droppableProps }) => (
          <div {...droppableProps}>
            {hasVariables &&
              xAxis.map((variable, index) => (
                <Draggable key={variable} draggableId={variable} index={index}>
                  {(provided, snapshot) => (
                    <VariableSelector
                      value={variable}
                      onSelect={id => replaceVariable(variable, id, field)}
                      components={{
                        button: (
                          <DraggableItem
                            variable={variable}
                            draggableProps={{ provided, snapshot }}
                            onRemove={() => removeVariable(variable, field)}
                          />
                        ),
                      }}
                    />
                  )}
                </Draggable>
              ))}
            {placeholder}
            <AddItemDropdown field={field} />
          </div>
        )}
      </DroppableZone>
    </Group>
  );
}
