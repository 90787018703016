import { api, q } from 'config/api';

export default function useWorkflowAction(id, overrides) {
  return q.useQuery({
    queryKey: ['workflowAction', id],
    queryFn: () =>
      api(
        `query workflowAction($id: ID!) {
          workflowAction(id: $id) {
            id
            disabled
            deleted
            action {
              id
              name
            }
            configuration
            fields {
              configuration
            }
            workflow {
              id
              skills {
                data {
                  id
                  name
                  skill {
                    id
                    name
                    icon
                    iconUrl
                    color
                  }
                  outputs {
                    disabled
                    output {
                      id
                      label
                      default
                    }
                  }
                }
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
