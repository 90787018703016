import { compact, find, flatMap, includes, isFunction, keys, sortBy, xor, range } from 'lodash';
import moment from 'moment';
import { createContext, useContext, useEffect, useReducer } from 'react';

import { useChartCtx } from '../context';
import { CHART_CONSTANTS, SMART_TIMEFRAMES } from '../data';

const ChartTypeContext = createContext();
const useChartType = () => useContext(ChartTypeContext);

const INIT_STATE = {
  disabledGroups: [],
  selectedCategoryKey: undefined,
};

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'resetState':
      return INIT_STATE;
    case 'setState':
      return { ...state, ...payload };
    default:
      return state;
  }
};

function ChartTypeProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  const {
    configuration: { showInterface },
    state: { chartInitialized, loading, parsedQueryResults, interpolatingTimeGroupKeys },
    helpers: {
      getChartConfig,
      parseChartValue,
      parseVariableId,
      parseVariableName,
      joinArrayViaSymbol,
      splitStringViaSymbol,
      cleanList,
      isUnknownValue,
      formatTime,
      formatTimeGroup,
    },
    TIMEFRAME_BINS,
    TIME_GROUP_DIMENSIONS,
    DIMENSIONS,
    DIMENSION_ALIASES,
    CONSTANTS: { SPLIT_SYMBOL_2, SPLIT_SYMBOL_3 },
  } = useChartCtx();

  const helpers = {
    generateItemKey: params => {
      const { item, keys = [], fallback } = params || {};
      if (!item) return;
      if (!keys?.length) return fallback;
      const variableKeys = cleanList(
        keys?.map(variable => {
          const key = parseVariableId(variable);
          const value = item[key];
          if (!value) return undefined;
          const name = parseVariableName(variable);
          const value_alias = item[DIMENSION_ALIASES[key]];
          return joinArrayViaSymbol(compact([name, key, value, value_alias]), SPLIT_SYMBOL_2);
        }),
      );
      const itemKey = joinArrayViaSymbol(variableKeys);
      return itemKey;
    },
    getUniqueCategoryLabels: categories => {
      const { timeframeBin } = getChartConfig();
      return cleanList(
        flatMap(categories, category =>
          splitStringViaSymbol(category).map(categoryKey => {
            const [, key, value, value_alias] = splitStringViaSymbol(categoryKey, SPLIT_SYMBOL_2);
            return key === DIMENSIONS.time.type
              ? formatTime(value, TIMEFRAME_BINS[timeframeBin]?.format)
              : value_alias || value;
          }),
        ),
      );
    },
  };

  const methods = {
    interpolateByTimeGroupKeys: (metricId, data, timeGroupKeys) => {
      if (!Array.isArray(data) || !data.length) return;
      if (!timeGroupKeys?.length) return data;

      const {
        disabledDays: _disabledDays,
        disabledHours: _disabledHours,
        timeframe,
        syncTimezones,
      } = getChartConfig();
      const disabledDays = _disabledDays || [];
      const disabledHours = _disabledHours || [];

      const metric = parseVariableId(metricId);

      if (!metric) return data;
      let filledData = [...data];

      const daysInMonth =
        SMART_TIMEFRAMES?.[timeframe?.start]?.daysInMonth?.() || moment().daysInMonth();

      const timeGroupRanges = {
        // TODO: camelCase!!!!
        hour_of_day: range(1, 25), // 1-24
        day_of_week: range(1, 8), // 1-7
        day_of_month: range(1, daysInMonth + 1), // 1-n
        month_of_year: range(1, 13), // 1-12
        quarter: range(1, 5), // 1-4
      };

      timeGroupKeys.forEach(timeGroupKey => {
        const timeGroupData = timeGroupRanges?.[timeGroupKey]?.map?.(value => {
          const disabledDay =
            timeGroupKey === TIME_GROUP_DIMENSIONS.day_of_week.type &&
            disabledDays.includes(parseInt(value, 10));
          const disabledHour =
            timeGroupKey === TIME_GROUP_DIMENSIONS.hour_of_day.type &&
            disabledHours.includes(parseInt(value, 10));
          if (disabledDay || disabledHour) return undefined;
          return {
            [timeGroupKey]: parseInt(value, 10),
            [metric]: null,
          };
        });
        filledData = [...timeGroupData, ...filledData];
      });

      // Dynamic fn leveraging momentjs
      const sortingValues = {
        hour_of_day: 'hour',
        day_of_week: 'day',
        day_of_month: 'date',
        month_of_year: 'month',
        quarter: 'quarter',
      };

      return sortBy(
        cleanList(filledData),
        timeGroupKeys.map(tgKey => v => {
          const sorter = sortingValues[tgKey];
          return formatTimeGroup(tgKey, v[tgKey], { useLocal: !syncTimezones, noFormat: true })[
            sorter || 'hour'
          ]();
        }),
      );
    },
    generateChartGraphData: (_data, options = {}) => {
      if (!chartInitialized || loading) return {};

      const { xAxis, yAxis, metrics } = getChartConfig();

      const list = [];
      const groups = {};
      const categories = [];

      metrics?.forEach((_metric, i) => {
        const { metric: metricId, calculation } = _metric;
        const metricName = parseVariableId(metricId);
        const data = methods.interpolateByTimeGroupKeys(
          metricId,
          _data,
          interpolatingTimeGroupKeys,
        );

        let perMetricGroupCount = 0;

        data?.reduce((r, item) => {
          // find and format this items value
          const value = parseChartValue(item[metricName]);

          // Find this item's category based on xAxis variables
          // with this item's corresponding values. A category is an x-axis tick.
          const category = helpers.generateItemKey({
            item,
            keys: options?.categoryKeys || xAxis,
            fallback: metricName,
          });

          if (!category) return r;

          categories.push(category);

          // Generate a group key based on yAxis variables
          // with this item's corresponding values.
          const subMetricGroupKey = helpers.generateItemKey({
            item,
            keys: yAxis,
            fallback: metricName,
          });

          const subMetricIndex = !!yAxis?.length ? perMetricGroupCount : i;

          const groupKey = joinArrayViaSymbol(
            cleanList([
              subMetricGroupKey
                ? joinArrayViaSymbol(['Metric', metricName, calculation], SPLIT_SYMBOL_3)
                : undefined,
              subMetricGroupKey,
            ]),
          );

          const existingItemGroupKey = !!groups[groupKey];

          // store any relevant/usable data here for convenience
          // when building the chart
          if (groupKey && !existingItemGroupKey) {
            groups[groupKey] = {
              metric: {
                ..._metric,
                color: CHART_CONSTANTS.COLORS[subMetricIndex % CHART_CONSTANTS.COLORS.length],
              },
            };
            perMetricGroupCount++;
          }

          // push item to the main list
          const existingItem = find(list, { category });

          if (existingItem) {
            if (!isUnknownValue(value)) existingItem[groupKey] = value;
          } else {
            const newItem = { category };
            if (!isUnknownValue(value)) newItem[groupKey] = value;
            list.push(newItem);
          }

          return r;
        }, []);
      });

      const data = {
        list: cleanList(list),
        groups,
        groupKeys: cleanList(keys(groups)),
        categories: cleanList(categories),
        uniqueCategories: helpers.getUniqueCategoryLabels(categories),
      };

      return data;
    },
  };

  const chartData = methods.generateChartGraphData(parsedQueryResults?.data);
  const disabledGroupKeys = state.disabledGroups?.map(group => group.value);
  const activeGroupsKeys = xor(chartData?.groupKeys, disabledGroupKeys);

  const calculatedState = {
    chartData,
    disabledGroupKeys,
    activeGroupsKeys,
  };

  helpers.isActiveGroup = groupKey => includes(activeGroupsKeys, groupKey);

  const actions = {
    toggleGroup: group =>
      dispatch({
        type: 'setState',
        payload: {
          disabledGroups: xor(state.disabledGroups, [group]),
        },
      }),
    selectCategory: _selectedCategoryKey => {
      if (!showInterface) return;
      const selectedCategoryKey = _selectedCategoryKey
        ? _selectedCategoryKey === state.selectedCategoryKey
          ? undefined
          : _selectedCategoryKey
        : undefined;
      dispatch({
        type: 'setState',
        payload: { selectedCategoryKey },
      });
    },
    dispatch,
  };

  useEffect(() => {
    if (!loading) return;
    dispatch({
      type: 'setState',
      payload: {
        selectedCategoryKey: undefined,
        disabledGroups: [],
      },
    });
  }, [loading]);

  const value = {
    state: {
      ...calculatedState,
      ...state,
    },
    methods,
    helpers,
    actions,
    CONSTANTS: CHART_CONSTANTS,
  };

  return (
    <ChartTypeContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </ChartTypeContext.Provider>
  );
}

export { ChartTypeContext, ChartTypeProvider, useChartType };
