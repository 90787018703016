import { isString, truncate as _truncate } from 'lodash';
import { Fragment } from 'react';

import { Tooltip } from '@optra/kit';

import { CONSTANTS } from '../data';

import { useChartCtx } from '../../chart-details/context';

export default function ChartValue(props) {
  const {
    children,
    displayName: _displayName,
    as = Fragment,
    enable = true,
    tooltip = true,
    truncate = CONSTANTS.MAX_LABEL_LENGTH,
    timeFormat,
    ...rest
  } = props;
  const {
    helpers: {
      getChartConfig,
      formatTime,
      formatTimeGroup,
      splitStringViaSymbol,
      isTimeDimension,
      isTimeGroupDimension,
    },
    CONSTANTS: { SPLIT_SYMBOL_2 },
    TIMEFRAME_BINS,
  } = useChartCtx();

  const passedProps = {
    ...rest,
  };

  const Component = as;

  if (!enable) {
    return <Component {...passedProps}>{children}</Component>;
  }

  if (!isString(children)) {
    return <Component {...passedProps} />;
  }

  const [name, key, root_value, value_alias] = splitStringViaSymbol(children, SPLIT_SYMBOL_2);

  if (!root_value) {
    return <Component {...passedProps}>{children}</Component>;
  }

  const { timeframeBin, syncTimezones } = getChartConfig();

  const isTimeGroup = isTimeGroupDimension(key);
  const isTime = isTimeDimension(key);

  const value = isTimeGroup
    ? formatTimeGroup(key, root_value, { useLocal: !syncTimezones })
    : isTime
    ? formatTime(root_value, timeFormat || TIMEFRAME_BINS[timeframeBin]?.format, syncTimezones)
    : root_value;

  const resolvedValue = value_alias || value;
  const truncatedValue = _truncate(resolvedValue, { length: truncate });

  const displayName = _displayName && name && !isTime;

  return (
    <Component {...passedProps}>
      {displayName && `${name}: `}
      {tooltip ? (
        <Tooltip label={resolvedValue} disabled={resolvedValue <= truncatedValue}>
          <span>{truncatedValue}</span>
        </Tooltip>
      ) : (
        truncatedValue
      )}
    </Component>
  );
}
