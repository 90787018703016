import cx from 'classnames';

import { Alert, ContextHeading, Panel, Button } from '@optra/kit';

import { useModalContext } from 'components/modal';

import { useChartCtx, useChartInterface } from './context';
import GlobalControls from './global-controls';

function Errors({ className }) {
  const { queries, mutations } = useChartCtx();

  const passedProps = {
    text: 'Something Went Wrong',
    variant: 'error',
    className,
  };

  if (
    !queries.chart.isError &&
    !queries.chartPreview.isError &&
    !mutations.updateChart.isError &&
    !mutations.removeChart.isError
  )
    return null;

  return (
    <Panel.Row>
      <Panel.Col className="space-y-3">
        {!!queries.chart.isError && (
          <Alert {...passedProps} details={queries.chart.error.message} />
        )}
        {!!queries.chartPreview.isError && (
          <Alert {...passedProps} details={queries.chartPreview.error.message} />
        )}
        {!!mutations.updateChart.isError && (
          <Alert {...passedProps} details={mutations.updateChart.error.message} />
        )}
        {!!mutations.removeChart.isError && (
          <Alert {...passedProps} details={mutations.removeChart.error.message} />
        )}
      </Panel.Col>
    </Panel.Row>
  );
}

///////////////////////
// INFO
///////////////////////

function Info({ className }) {
  const {
    state: { saving, loading: _loading, filterDefinedQueryString, isCustomQuery, unsavedChanges },
    actions: { setQuery, resetChart },
    helpers: { getChart, trimExtraWhitespace },
  } = useChartCtx();
  const {
    actions: { setShowSqlInput },
  } = useChartInterface();

  if (!unsavedChanges && !isCustomQuery) return null;

  const loading = !!(_loading || saving);

  const chart = getChart();
  const { query } = chart || {};

  const queryConfirmMessage = `Are you sure you want to set the query back to the defined filters?

CUSTOM QUERY (CURRENT): 

${trimExtraWhitespace(query)}

FILTER DEFINED QUERY: 

${trimExtraWhitespace(filterDefinedQueryString)}`;

  return (
    <Panel.Row>
      <Panel.Col className="space-y-3">
        {unsavedChanges && (
          <Alert
            text={saving ? 'Saving...' : 'Unsaved Changes'}
            loading={saving}
            components={{
              after: !loading && (
                <Button
                  variant="plain"
                  className="bg-blue/20 dark:bg-blue/30"
                  size="xs"
                  onClick={resetChart}
                >
                  Clear
                </Button>
              ),
            }}
            className={cx(loading && 'animate-pulse', className)}
          />
        )}
        {isCustomQuery && (
          <Alert
            text="Custom Query"
            details="There is currently a custom defined query string."
            components={{
              after: (
                <Button
                  variant="plain"
                  className="bg-blue/20 dark:bg-blue/30"
                  size="xs"
                  onClick={() => {
                    if (window.confirm(queryConfirmMessage)) {
                      setQuery();
                      setShowSqlInput(false);
                    }
                  }}
                >
                  Clear
                </Button>
              ),
            }}
            className={className}
          />
        )}
      </Panel.Col>
    </Panel.Row>
  );
}

///////////////////////
// TOP BAR CONTROLS
///////////////////////

function ModalHeading({ className, ...rest }) {
  const { handleClose } = useModalContext();
  const {
    state: { unsavedChanges },
    queries,
    helpers: { getChart },
  } = useChartCtx();

  const { name } = getChart() || {};

  return (
    <ContextHeading
      heading={!queries.chart.isLoading && (name || 'Untitled')}
      onDismiss={() => {
        if (!unsavedChanges) {
          handleClose();
          return;
        }
        if (window.confirm('Are you sure? You currently have unsaved changes.')) {
          handleClose();
        }
      }}
    />
  );
}

///////////////////////
// TOP BAR
///////////////////////

export default function TopBar() {
  const {
    configuration: { inModal, readOnly },
    queries,
    helpers: { getChart },
    CONSTANTS: { X_SPACING, Y_SPACING },
  } = useChartCtx();
  const {
    state: { isMobile },
  } = useChartInterface();

  const { name } = getChart() || {};

  return isMobile ? (
    <Panel.Col>
      <Panel.Row>
        <Panel.Col className={Y_SPACING}>
          <Panel.Row>
            {!!inModal ? (
              <ModalHeading />
            ) : (
              <ContextHeading heading={!queries.chart.isLoading && (name || 'Untitled')} />
            )}
          </Panel.Row>
          {!readOnly && (
            <Panel.Row>
              <GlobalControls />
            </Panel.Row>
          )}
          {!queries.chart.isLoading && (
            <>
              <Errors />
              <Info />
            </>
          )}
        </Panel.Col>
      </Panel.Row>
    </Panel.Col>
  ) : (
    <Panel.Col>
      <Panel.Row className={X_SPACING} alignItems>
        <Panel.Col>
          {!!inModal ? (
            <ModalHeading />
          ) : (
            <ContextHeading heading={!queries.chart.isLoading && (name || 'Untitled')} />
          )}
        </Panel.Col>
        {!queries.chart.isLoading && (
          <>
            <Panel.Col collapse>
              <Errors />
              <Info />
            </Panel.Col>
            {!readOnly && (
              <Panel.Col collapse>
                <GlobalControls />
              </Panel.Col>
            )}
          </>
        )}
      </Panel.Row>
    </Panel.Col>
  );
}
