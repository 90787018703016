import { MagnifyingGlass } from '@phosphor-icons/react';
import cx from 'classnames';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';

import { DetailHeading, Dropdown, UiState, Icon, SearchField } from '@optra/kit';

import { useChartCtx } from '../context';

function MetricList({
  list = [],
  value,
  disableVariables,
  variableAlreadyInUse,
  variableIncompatible,
  isMetricTablePopulated,
  onClick,
}) {
  const [searchFocused, setSearchFocused] = useState(false);

  const [search, setSearch] = useState('');
  const filteredList = filter(list, ({ name, id }) => {
    const searchableValue = `${name} - ${id}`?.toLowerCase();
    return includes(searchableValue, search.toLowerCase());
  }).map(item => ({
    ...item,
    populated: !!isMetricTablePopulated(item.id),
  }));
  const orderedList = orderBy(filteredList, ['db', 'populated', 'name'], ['asc', 'desc', 'asc']);

  return (
    <Dropdown.MenuBody
      divide={false}
      scrolling
      className="p-5"
      // prevent a-z keys from focusing items when trying to search
      onKeyDown={e => !!searchFocused && e.stopPropagation()}
    >
      <DetailHeading className="px-3">METRICS</DetailHeading>
      <div className="p-4">
        <SearchField
          value={search}
          onChange={setSearch}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
          placeholder="Search Metrics…"
          debounce={10}
        />
      </div>
      {!orderedList?.length ? (
        <UiState variant="empty" text="No metrics..." icon={{ component: MagnifyingGlass }} />
      ) : (
        orderedList.map(({ id, name, db, table, populated }, i) => {
          const active = id === value;
          const inUse = disableVariables && variableAlreadyInUse(id);
          const incompatibleTable = disableVariables && variableIncompatible(id);
          const disabled = inUse || incompatibleTable;

          return (
            <Dropdown.Item
              key={`${id}_${i}`}
              text={name}
              title={incompatibleTable ? 'Tables do not match.' : undefined}
              detail={
                <span className="flex flex-nowrap items-center space-x-2">
                  <span className="flex flex-nowrap items-center space-x-1">
                    <Icon
                      name={incompatibleTable ? 'Lock' : 'Database'}
                      weight="regular"
                      size="xs"
                      className="opacity-60"
                    />
                    <span>
                      {db}.{table}
                    </span>
                  </span>
                  {!populated && (
                    <>
                      <span className="opacity-50 mx-2">•</span>
                      <span className="flex flex-nowrap items-center space-x-1">No Data</span>
                    </>
                  )}
                </span>
              }
              uppercase={false}
              small={false}
              className={cx(
                'rounded-md',
                (incompatibleTable || (disabled && !active)) && 'opacity-30',
              )}
              disabled={disabled}
              onClick={disabled ? null : () => onClick(id)}
              active={active}
            />
          );
        })
      )}
    </Dropdown.MenuBody>
  );
}

function TimeDimensionList({ list = [], value, disableVariables, variableAlreadyInUse, onClick }) {
  return (
    <Dropdown.MenuBody divide={false} scrolling className="p-5">
      <DetailHeading className="px-3 mb-4">TIME</DetailHeading>
      {list.map(({ name, type, selectable }, i) => {
        const active = type === value;
        const inUse = disableVariables && variableAlreadyInUse(type);
        const disabled = inUse;
        if (!selectable) return null;
        return (
          <Dropdown.Item
            key={`${type}_${i}`}
            text={name}
            uppercase={false}
            className={cx('rounded-md', disabled && !active && 'opacity-30')}
            disabled={disabled}
            onClick={disabled ? null : () => onClick(type)}
            active={active}
          />
        );
      })}
    </Dropdown.MenuBody>
  );
}

function DimensionList({ list = [], value, disableVariables, variableAlreadyInUse, onClick }) {
  return (
    <Dropdown.MenuBody divide={false} scrolling className="p-5">
      <DetailHeading className="px-3 mb-4">DIMENSIONS</DetailHeading>
      {list.map(({ name, type, selectable }, i) => {
        const active = type === value;
        const inUse = disableVariables && variableAlreadyInUse(type);
        const disabled = inUse;
        if (!selectable) return null;
        return (
          <Dropdown.Item
            key={`${type}_${i}`}
            text={name}
            uppercase={false}
            className={cx('rounded-md', disabled && !active && 'opacity-30')}
            disabled={disabled}
            onClick={disabled ? null : () => onClick(type)}
            active={active}
          />
        );
      })}
    </Dropdown.MenuBody>
  );
}

function VariablesDropdownMenu({
  onSelect = noop,
  value,
  showTimeDimensions = true,
  showDimensions = true,
  disableVariables = true,
}) {
  const {
    queries,
    GENERAL_DIMENSIONS,
    TIME_DIMENSIONS,
    helpers: {
      getUsedVariables,
      getChartConfigMetrics,
      variableTableMatchesConfig,
      isMetricTablePopulated,
    },
  } = useChartCtx();

  const usedVariables = getUsedVariables();

  const { isLoading, isRefetching } = queries.chartConfigMetrics;
  const loading = isLoading || isRefetching;
  const chartMetrics = getChartConfigMetrics();

  const variableAlreadyInUse = variable => includes(usedVariables, variable);
  const variableIncompatible = variable => !variableTableMatchesConfig(variable);

  const onClick = variable => {
    if (disableVariables && variableAlreadyInUse(variable)) {
      return;
    }
    onSelect(variable);
  };

  if (loading) {
    return <UiState margin={false} className="m-10" />;
  }

  if (!Array.isArray(chartMetrics)) {
    return <UiState variant="error" margin={false} className="m-10" />;
  }

  const passedProps = {
    value,
    disableVariables,
    variableAlreadyInUse,
    variableIncompatible,
    isMetricTablePopulated,
    onClick,
  };

  return (
    <div
      className={cx(
        'flex flex-row flex-nowrap',
        'divide-x divide-light-fg-tertiary dark:divide-gray-200/10',
      )}
    >
      <MetricList list={chartMetrics} {...passedProps} />
      {!!showTimeDimensions && (
        <TimeDimensionList
          list={keys(TIME_DIMENSIONS).map(time_key => TIME_DIMENSIONS[time_key])}
          {...passedProps}
        />
      )}
      {!!showDimensions && (
        <DimensionList
          list={keys(GENERAL_DIMENSIONS).map(dimension_key => GENERAL_DIMENSIONS[dimension_key])}
          {...passedProps}
        />
      )}
    </div>
  );
}

function VariableSelector({ components = {}, className, dropdown = true, ...rest }) {
  if (!dropdown) {
    return <VariablesDropdownMenu {...rest} />;
  }

  const { button } = components;

  return (
    <Dropdown
      className={className}
      components={{
        button,
        body: <VariablesDropdownMenu {...rest} />,
      }}
    />
  );
}

export default VariableSelector;
