import cx from 'classnames';
import { isBoolean, isDate, isEmpty } from 'lodash';
import moment from 'moment';
import { useImperativeHandle, useState, useCallback, forwardRef } from 'react';
import { useUpdateEffect } from 'react-use';

import { DetailHeading, Dropdown, DayPicker, Modal, Button } from '@optra/kit';

function Calendar({ label, selected, ...rest }) {
  return (
    <Dropdown.MenuBody divide={false}>
      <div className="p-2 pb-0">
        <div
          className={cx(
            'flex flex-col items-center',
            'text-center p-3 rounded-sm cursor-default',
            'bg-gray-200/30 dark:bg-black-900',
          )}
        >
          <DetailHeading>{label}</DetailHeading>
        </div>
      </div>
      <div className="p-5">
        <DayPicker
          modifiersClassNames={{
            selected: selected ? '!bg-primary-400 !text-white' : '!border !border-gray-400',
            today: 'bg-primary/10 hover:bg-primary/10 text-primary',
            disabled: '!opacity-[15%]',
            outside: 'opacity-40',
          }}
          {...rest}
        />
      </div>
    </Dropdown.MenuBody>
  );
}

// NOTE: compatible with both controlled and uncontrolled paradigms
// TODO: Make mobile friendly
const DateRangeSelect = forwardRef((props, ref) => {
  const {
    value: _value,
    initialValue,
    resetValue: _resetValue,
    onChange,
    open: _open,
    onClose,
    maxDate: _maxDate,
    showButton = false,
  } = props || {};

  const fallbackValue = {
    from: moment().startOf('month').toDate(),
    to: moment().endOf('day').toDate(),
    selected: false,
  };

  const controlled = !isEmpty(_value);
  const resetValue = {
    from: _resetValue?.from || initialValue?.from || fallbackValue.from,
    to: _resetValue?.to || initialValue?.to || fallbackValue.to,
    selected: _resetValue?.selected || fallbackValue.selected,
  };

  const [value, setValue] = useState({
    from: _value?.from || initialValue?.from || fallbackValue.from,
    to: _value?.to || initialValue?.to || fallbackValue.to,
    selected: _value?.selected ?? initialValue?.selected ?? fallbackValue.selected,
  });
  useUpdateEffect(() => {
    setValue(prev => ({
      from: _value?.from || prev.from,
      to: _value?.to || prev.to,
      selected: _value?.selected ?? prev.selected,
    }));
  }, [_value?.from, _value?.to, _value?.selected]);

  // NOTE: consider making open/close controlled/uncontrolled instead of both
  const [open, setOpen] = useState(_open);
  useUpdateEffect(() => {
    if (isBoolean(_open)) {
      setOpen(_open);
    }
  }, [_open]);

  const close = useCallback(() => {
    setOpen(false);
    onClose?.();
  }, [setOpen, onClose]);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setOpen(true),
      close,
      toggle: () => setOpen(prev => !prev),
      value,
    }),
    [setOpen, value, close],
  );

  const { from: _from, to: _to, selected } = value || {};
  const from = moment(_from).startOf('day');
  const to = moment(_to).endOf('day');
  const maxDate = !isDate(_maxDate) ? null : moment(_maxDate).endOf('day');

  const isResettable =
    !from.isSame(moment(resetValue.from).startOf('day')) ||
    !to.isSame(moment(resetValue.to).endOf('day'));

  return (
    <>
      {showButton && (
        <Button
          icon={selected ? 'Check' : 'CalendarBlank'}
          onClick={() => setOpen(true)}
          size="xs"
          variant="tertiary"
        >
          Range
        </Button>
      )}
      <Modal
        isOpen={open}
        onClose={close}
        windowProps={{ autoWidth: true, dialogClassName: 'min-h-[550px]' }}
      >
        <Modal.Header
          heading="Select Date Range"
          components={{
            after: (
              <>
                {isResettable && (
                  <Button
                    variant="secondary"
                    size="xs"
                    onClick={() => {
                      if (!controlled) setValue(resetValue);
                      onChange?.(resetValue);
                    }}
                  >
                    Reset
                  </Button>
                )}
              </>
            ),
          }}
        />
        <Modal.Body className="flex flex-col items-center">
          <div
            className={cx(
              'flex flex-row flex-nowrap items-stretch',
              'divide-x divide-light-fg-tertiary dark:divide-gray-200/10',
            )}
          >
            <Calendar
              label="From"
              date={from.toDate()}
              maxDate={to.toDate()}
              selected={selected}
              onDateChange={date => {
                const nextFrom = moment(date).startOf('day').toDate();
                if (!controlled) setValue(prev => ({ ...prev, from: nextFrom }));
                onChange?.({ from: nextFrom, to: to.toDate(), selected });
              }}
            />
            <Calendar
              label="To"
              date={to.toDate()}
              minDate={from.toDate()}
              maxDate={maxDate}
              selected={selected}
              onDateChange={date => {
                const nextTo = moment(date).endOf('day').toDate();
                if (!controlled) setValue(prev => ({ ...prev, to: nextTo }));
                onChange?.({ to: nextTo, from: from.toDate(), selected });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-center space-x-2">
          <Button
            variant="secondary"
            onClick={() => {
              setValue(prev => ({
                ...prev,
                selected: false,
              }));
              onChange?.({ from: from.toDate(), to: to.toDate(), selected: false });
              close();
            }}
          >
            Deselect
          </Button>
          <Button
            onClick={() => {
              setValue(prev => ({
                ...prev,
                selected: true,
              }));
              onChange?.({ from: from.toDate(), to: to.toDate(), selected: true });
              close();
            }}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default DateRangeSelect;
