import cx from 'classnames';
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { Icon, Spinner, Text } from '@optra/kit';

import { useInputFocus } from 'hooks';

import { useChartCtx } from './context';

function QueryInputControls({ onSubmit, isDirty, isLoading }) {
  const {
    CONSTANTS: { PADDING },
  } = useChartCtx();

  return (
    <div className={cx('absolute top-0 right-0 h-full', 'flex items-stretch')}>
      <div className={cx('flex-0 self-stretch', 'w-10', 'bg-gradient-to-l from-[#000]')}></div>
      <div className={cx('flex-1 bg-[#000] p-2.5', 'rounded-r-md', PADDING)}>
        <div className="flex flex-row space-x-3 flex-nowrap items-center">
          <button
            className={cx(
              'inline-flex items-center lg:space-x-2',
              'text-sm font-bold text-primary',
              'py-1 px-2',
              !isLoading && 'hover:bg-white/10',
              'rounded-md',
              'animate-fade-in',
              'border border-gray-800',
              'opacity-100',
            )}
            onClick={onSubmit}
            disabled={isLoading}
          >
            {isLoading ? (
              <Spinner color="gradient" className="animate-fade-in h-5 w-5" />
            ) : (
              <>
                <Text className="hidden lg:inline">
                  {isDirty && <span className="animate-fade-in">UPDATE</span>} QUERY
                </Text>
                <Icon name="MagnifyingGlass" weight="duotone" size="sm" className="rotate-90" />
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default function QueryInput() {
  const {
    state: { loading },
    actions: { updateChart },
    helpers: { getChart },
    CONSTANTS: { PADDING },
  } = useChartCtx();

  const { query = '' } = getChart() || {};

  const {
    handleSubmit,
    register,
    formState: { dirtyFields, isSubmitSuccessful },
    setFocus,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      query,
    },
  });
  const textareaRef = useRef(null);
  const { ref, ...registerProps } = register('query');
  const watchQuery = watch('query');
  useInputFocus(setFocus, 'query');

  const handleOnSubmit = handleSubmit(form => {
    if (loading) {
      return;
    }
    updateChart(form);
  });

  useEffect(() => {
    reset({ query }, { keepDirty: false });
  }, [query, reset]);

  useEffect(() => {
    if (!isSubmitSuccessful) {
      return;
    }
    reset({ query: watchQuery }, { keepDirty: false });
  }, [isSubmitSuccessful, reset, watchQuery]);

  useEffect(() => {
    if (textareaRef?.current) {
      const { current } = textareaRef;
      current.style.height = '0px';
      const scrollHeight = current.scrollHeight;
      current.style.height = scrollHeight + 'px';
    }
  }, [watchQuery]);

  return (
    <form className="absolute inset-0 w-full h-full p-0 block" onSubmit={handleOnSubmit}>
      <div className={cx('absolute inset-0 overflow-auto scroll-bar-hidden', 'bg-[#000]')}>
        <textarea
          name="query"
          {...registerProps}
          ref={e => {
            ref(e);
            textareaRef.current = e;
          }}
          className={cx(
            'relative w-full min-h-full',
            'whitespace-pre font-mono text-xs leading-5',
            'bg-transparent border-0 ring-0 focus:ring-0 outline-transparent border-transparent',
            'scroll-bar-hidden',
            'bg-transparent text-primary-300 caret-white',
            PADDING,
          )}
        />
      </div>
      <QueryInputControls onSubmit={handleSubmit} isLoading={loading} isDirty={dirtyFields.query} />
    </form>
  );
}
