import cx from 'classnames';
import { format } from 'date-fns';
import { truncate, snakeCase, isBoolean, isEmpty, filter } from 'lodash';
import moment from 'moment';
import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { Card, Button, Text, Heading, Table, DetailList, Icon, Spinner } from '@optra/kit';

import DateRangeSelect from 'components/date-range-select';
import { useFeature } from 'components/feature';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import Select from 'components/select';
import SkillIcon from 'components/skill-icon';
import TimestreamActionDataFields from 'components/timestream-action-data-fields';
import ValidationError from 'components/validation-error';
import { api, q, useOnSuccess } from 'config/api';
import { useWorkflowAction } from 'queries';

function AvailableExport({ exp = {}, variant = 'secondary' }) {
  if (!exp) return null;
  return (
    <Table.Row className={cx(exp.isInProgress && 'animate-fade-in')}>
      <Table.TD>
        <Text>{exp.tableName}</Text>
        <DetailList size="xs" details={[format(new Date(exp.timestamp), 'PPp')]} />
      </Table.TD>
      <Table.TD collapsed>
        {!!exp.isTimedOut ? (
          <Text className="flex flex-row flex-nowrap items-center whitespace-nowrap space-x-1 text-sm">
            <Icon name="Warning" color="red" weight="bold" size="xs" />
            <span>Timed Out</span>
          </Text>
        ) : !!exp.isInProgress ? (
          <Text className="flex flex-row flex-nowrap items-center whitespace-nowrap space-x-1 text-sm">
            <Spinner color="gradient" size="sm" />
            <span>Generating...</span>
          </Text>
        ) : (
          <Button href={exp?.url} size="xs" variant={variant}>
            Download
          </Button>
        )}
      </Table.TD>
    </Table.Row>
  );
}

function AvailableExports({ availableExports = [] }) {
  const generatingExports = filter(availableExports, ['isInProgress', true]);
  const completedExports = filter(availableExports, ['isInProgress', false]);
  return (
    <div className="mt-4 space-y-4">
      {!!generatingExports?.length && (
        <Table>
          <Table.Body>
            {generatingExports?.map?.((exp, idx) => {
              return <AvailableExport key={idx} exp={exp} />;
            })}
          </Table.Body>
        </Table>
      )}
      {!!completedExports?.length && (
        <div className="space-y-2">
          <Label>Exports</Label>
          <Table>
            <Table.Body>
              {completedExports?.map?.((exp, idx) => {
                return (
                  <AvailableExport
                    key={idx}
                    exp={exp}
                    variant={!idx && !generatingExports?.length ? 'primary' : 'secondary'}
                  />
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
}

export default function TimestreamActionForm(props) {
  const { onClose } = props;
  const { workflowActionId } = useParams();
  const allSkillOutputsEnabled = useFeature('allSkillOutputs', 'global');
  const Form = useForm({
    defaultValues: {
      configuration: {
        shared: {
          dbName: '',
          tableName: '',
          newTableName: '',
        },
      },
    },
  });
  const selectedTableName = Form.watch('configuration.shared.tableName');

  const [error, setError] = useState();
  const selectedExportTable = useRef();
  const exportRangeSelector = useRef();

  const defaults = useWorkflowAction(workflowActionId, {
    refetchInterval: 60000,
  });

  useOnSuccess(
    () => {
      const workflowAction = defaults.data.workflowAction;
      const configuration = {
        ...workflowAction.configuration,
        shared: {
          ...workflowAction.configuration?.shared,
          dbName:
            workflowAction.configuration?.shared?.dbName ||
            workflowAction.configuration?.defaultDbName ||
            '',
        },
      };

      workflowAction?.workflow?.skills?.data?.forEach(workflowSkill => {
        configuration[workflowSkill?.id] = {
          payload: {},
        };

        workflowSkill?.outputs
          ?.filter(({ output }) => !output.default)
          .forEach(output => {
            const existingConfig =
              workflowAction?.configuration?.[workflowSkill.id]?.payload?.[output.output.id];
            configuration[workflowSkill.id]['payload'][output.output.id] = {
              id: output.output.id,
              as: existingConfig?.as || snakeCase(output.output.id),
              enabled: isBoolean(existingConfig?.enabled) ? existingConfig?.enabled : true,
            };
          });
      });

      Form.reset({
        configuration,
      });
    },
    { isSuccess: defaults.isSuccess },
    [Form, defaults.data],
  );

  const qc = q.useQueryClient();
  const upsertTable = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createTimestreamTable ($form: runWorkflowActionMutationForm!) {
          workflowAction: runWorkflowActionMutation(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        {
          form: {
            id: workflowActionId,
            fn: 'upsertTable',
            props: form,
          },
        },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
    },
  });

  const removeTable = q.useMutation({
    mutationFn: form =>
      api(
        `mutation removeTimestreamTable ($form: runWorkflowActionMutationForm!) {
          workflowAction: runWorkflowActionMutation(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        {
          form: {
            id: workflowActionId,
            fn: 'removeTable',
            props: form,
          },
        },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
    },
  });

  const exportTable = q.useMutation({
    mutationFn: ({ tableName, timeRange }) =>
      api(
        `mutation exportTimestreamTable ($form: runWorkflowActionMutationForm!) {
          workflowAction: runWorkflowActionMutation(form: $form) {
            id
            workflow {
              id
            }
          }
        }`,
        {
          form: {
            id: workflowActionId,
            fn: 'exportTable',
            props: { tableName, timeRange },
          },
        },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflows'] });
      qc.invalidateQueries({ queryKey: ['workflowAction', workflowActionId] });
      qc.invalidateQueries({ queryKey: ['workflow', r?.workflowAction?.workflow?.id] });
    },
  });

  const configuration = defaults.data?.workflowAction?.configuration;

  const handleSubmit = Form.handleSubmit(form => {
    setError(null);

    return upsertTable.mutateAsync(form, {
      onSuccess() {
        onClose();
      },
      onError(error) {
        setError(error);
      },
    });
  });

  const loading = defaults.error || defaults.isLoading;
  const workflowAction = defaults?.data?.workflowAction;
  const workflowSkills = defaults?.data?.workflowAction?.workflow?.skills?.data || [];

  return (
    <>
      <ModalBody as="form" onSubmit={handleSubmit} className="space-y-4">
        {error && (
          <Message variant="danger" title="Couldn't Save Action">
            {error.message}
          </Message>
        )}
        {defaults.error && (
          <Message variant="danger" title="Couldn't Load Action">
            {defaults.error.message}
          </Message>
        )}
        <div>
          <Text variant="label" size="xs" color="muted">
            Run a Query
          </Text>
          <Card variant="secondary" className="text-right">
            <Button to="query" size="xs" variant="tertiary">
              Query
            </Button>
          </Card>
        </div>
        <div>
          <Text variant="label" size="xs" color="muted">
            Source
          </Text>
          <Card variant="secondary" className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="configuration.shared.dbName">Database Name (Account Max: 1)</Label>
              <Input
                type="text"
                {...Form.register('configuration.shared.dbName')}
                readOnly={
                  loading || !isEmpty(defaults?.data?.workflowAction.configuration?.defaultDbName)
                }
              />
              <ValidationError errors={Form.errors} name="configuration.shared.dbName" />
            </div>

            <div className="space-y-2">
              <Label htmlFor="configuration.shared.tableName">Table Name</Label>
              <div className="flex space-x-2">
                <Select {...Form.register('configuration.shared.tableName')} readOnly={loading}>
                  <option value="">Select a Table</option>
                  <option value="$$NEW">Create New</option>
                  {(configuration?.availableTables || []).map(table => (
                    <option key={table.arn} value={table.tableName}>
                      {table.tableName}
                      {table.tableStatus === 'DELETING' ? ' (deleting)' : ''}
                    </option>
                  ))}
                </Select>
                <Button
                  size="xs"
                  loading={loading || removeTable.isPending}
                  onClick={e => {
                    e.preventDefault();
                    removeTable.mutate({ tableName: selectedTableName });
                  }}
                >
                  Remove
                </Button>
              </div>

              <ValidationError errors={Form.errors} name="configuration.shared.tableName" />

              <Input
                className={selectedTableName === '$$NEW' ? '' : 'hidden'}
                type="text"
                {...Form.register('configuration.shared.newTableName')}
                readOnly={loading}
              />
              <ValidationError errors={Form.errors} name="configuration.shared.newTableName" />
            </div>
          </Card>
        </div>
        <div>
          <Text variant="label" size="xs" color="muted">
            Export Data
          </Text>
          <Card variant="secondary">
            <div className="flex space-x-2">
              <Select ref={selectedExportTable} readOnly={loading}>
                <option value="">Select a Table</option>
                {(configuration?.availableTables || []).map(table => (
                  <option key={table.arn} value={table.tableName}>
                    {table.tableName}
                    {table.tableStatus === 'DELETING' ? ' (deleting)' : ''}
                  </option>
                ))}
              </Select>

              <DateRangeSelect ref={exportRangeSelector} showButton />

              <Button
                icon="DownloadSimple"
                loading={exportTable.isPending}
                hideChildrenWhenLoading={false}
                onClick={() => {
                  const { from, to, selected } = exportRangeSelector.current.value;
                  exportTable.mutate({
                    tableName: selectedExportTable.current.value,
                    timeRange: selected
                      ? {
                          from: moment(from).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                          to: moment(to).format('YYYY-MM-DDTHH:mm:ss.SSS'),
                        }
                      : null,
                  });
                }}
                size="xs"
                variant="tertiary"
              >
                Generate
              </Button>
            </div>
            <ValidationError message={exportTable?.error?.message} className="mt-3" />
            {!isEmpty(configuration?.availableExports) && (
              <AvailableExports availableExports={configuration?.availableExports} />
            )}
          </Card>
        </div>
        <div>
          <Text variant="label" size="xs" color="muted">
            Table Columns (Metrics)
          </Text>
          {workflowSkills.map?.(workflowSkill => (
            <Card className="mb-2" variant="secondary" key={workflowSkill.id}>
              <div className="flex items-center">
                <SkillIcon
                  size="sm"
                  color={workflowSkill?.skill?.color}
                  icon={workflowSkill?.skill?.icon}
                  iconUrl={workflowSkill?.skill?.iconUrl}
                  className="mr-3"
                />
                <Heading level={4}>
                  {truncate(workflowSkill?.name, {
                    length: 32,
                  })}
                </Heading>
              </div>

              {(workflowSkill.outputs || [])
                .filter(({ output }) => !output.default)
                .map(({ output }) => (
                  <TimestreamActionDataFields
                    key={output.id}
                    Form={Form}
                    output={output}
                    loading={loading}
                    workflowSkill={workflowSkill}
                  />
                ))}
            </Card>
          ))}
        </div>
        {allSkillOutputsEnabled && !isEmpty(workflowAction?.configuration?.otherOutputs) && (
          <div>
            <Text variant="label" size="xs" color="muted">
              Other Metrics
            </Text>
            <Card variant="secondary">
              {workflowAction?.configuration?.otherOutputs?.map(output => (
                <Label key={output} className="mb-1">
                  {output}
                </Label>
              ))}
            </Card>
          </div>
        )}
      </ModalBody>

      <ModalFooter>
        <Button size="xl" loading={loading || Form.formState.isSubmitting} onClick={handleSubmit}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
}
