import { Layout, Cursor } from '@phosphor-icons/react';
import cx from 'classnames';
import { isEmpty } from 'lodash';
import { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, Outlet, useNavigate, Navigate } from 'react-router-dom';
import { useKey } from 'react-use';

import {
  AddPlaceholderButton,
  UiState,
  NavList,
  PrimaryCTAButton,
  Button,
  PageWrapper,
  FilterContainer,
  Filter,
  Dropdown,
  SearchField,
  DROPDOWN_MENU_PLACEMENT,
} from '@optra/kit';

import ChartWorkspaceStarButton from 'components/chart-workspace-star-button';
import ChartsWorkspace from 'components/charts-workspace';
import ChartsWorkspaceEdit from 'components/charts-workspace-edit';
import IntersectionLoadMore from 'components/intersection-load-more';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import { useWorkspaces } from 'queries';

export default function Charts() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [search, setSearch] = useState('');
  const [searchFocused, setSearchFocused] = useState(false);
  const searchInputRef = useRef(null);
  useKey('/', e => {
    if (
      searchInputRef.current !== document.activeElement &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      searchInputRef.current.focus();
      e.preventDefault();
    }
  });

  const sortOptions = {
    createdAt_desc: t('Newest'),
    createdAt_asc: t('Oldest'),
    name_asc: t('Name (A-Z)'),
    name_desc: t('Name (Z-A)'),
  };
  const [sort, setSort] = useState('createdAt_desc');

  const filterOptions = {
    all: t('All'),
    starred: t('Starred'),
    private: t('Private'),
  };
  const [filter, setFilter] = useState('all');
  const memoizedFilter = useMemo(
    () => ({
      ...(filter === 'starred' ? { starred: true } : {}),
      ...(filter === 'private' ? { allowedAccess: true } : {}),
      ...(!isEmpty(search) ? { $search: search } : {}),
    }),
    [filter, search],
  );

  const memoizedSort = useMemo(() => {
    const sortOptions = sort.split('_');
    return {
      by: sortOptions?.at(0),
      direction: sortOptions?.at(1),
    };
  }, [sort]);

  const Workspaces = useWorkspaces({
    list: {
      filter: memoizedFilter,
      sort: memoizedSort,
    },
  });
  const workspaces = (Workspaces.data?.pages || []).flatMap(page => page?.list?.data);

  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);
  const workspaceRef = useRef();

  const [readOnly, loadingRoles] = useHasRoles(['chartViewerOnly'], true);

  const qc = q.useQueryClient();

  // NOTE: This pattern will cause mutations to trigger re-renders at this level, keep an eye on this
  const mutations = {
    createWorkspace: q.useMutation({
      mutationFn: form =>
        api(
          `mutation createWorkspace($form: createWorkspaceForm!) {
            createWorkspace(form: $form) {
              id
            }
          }`,
          { form },
        ),
      onSuccess(data) {
        qc.invalidateQueries({ queryKey: ['workspaces'] });
        setShowWorkspaceModal(false);
      },
    }),
    removeWorkspace: q.useMutation({
      mutationFn: id =>
        api(
          `mutation removeWorkspace($id: ID!) {
            removeWorkspace(id: $id) {
              id
            }
          }`,
          { id },
        ),
      onSuccess() {
        qc.invalidateQueries({ queryKey: ['workspaces'] });
      },
    }),
    duplicateWorkspace: q.useMutation({
      mutationFn: form =>
        api(
          `mutation duplicateWorkspace($form: duplicateWorkspaceForm!) {
            workspace: duplicateWorkspace(form: $form) {
              id
            }
          }`,
          { form },
        ),
      onSuccess() {
        qc.invalidateQueries({ queryKey: ['workspaces'] });
        if (showWorkspaceModal) {
          setShowWorkspaceModal(false);
          return;
        }
        workspaceRef.current.duplicateModal.close();
      },
    }),
  };

  const actions = {
    createWorkspace: form => {
      if (!isEmpty(form.duplicateFromId)) {
        mutations.duplicateWorkspace.mutate(
          {
            sourceWorkspaceId: form.duplicateFromId,
            name: form.name,
            config: form.config,
          },
          {
            onSuccess: data => navigate(`/charts/${data?.workspace.id}`),
          },
        );
        return;
      }
      mutations.createWorkspace.mutate(
        {
          name: form.name,
          config: form.config,
        },
        {
          onSuccess: data => navigate(data?.createWorkspace.id),
        },
      );
    },
    removeWorkspace: id => {
      if (window.confirm('Are you sure you want to remove this workspace?')) {
        mutations.removeWorkspace.mutate(id, {
          onSuccess: () => navigate('/charts'),
        });
      }
    },
    duplicateWorkspace: form => {
      mutations.duplicateWorkspace.mutate(form, {
        onSuccess: data => navigate(`/charts/${data?.workspace.id}`),
      });
    },
  };

  const loading = Workspaces.isLoading || loadingRoles;

  return (
    <Routes>
      <Route
        element={
          <PageWrapper
            title="Charts"
            loading={loading}
            error={Workspaces.error}
            components={{
              leftToggle: (
                <Button variant="secondary" size="sm" as="span">
                  Workspaces
                </Button>
              ),
              left: ({ close }) => (
                <div className="w-56">
                  <div className="animate-fade-in space-y-6">
                    <div className="relative">
                      <FilterContainer className="justify-between h-11">
                        <Filter
                          variant="plain"
                          menu={{
                            text: filterOptions[filter],
                            body: Object.keys(filterOptions).map(option => (
                              <Dropdown.Item
                                key={option}
                                text={filterOptions[option]}
                                active={option === filter}
                                onClick={() => setFilter(option)}
                              />
                            )),
                          }}
                          placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                        />
                        <Filter
                          variant="plain"
                          menu={{
                            text: sortOptions[sort],
                            body: Object.keys(sortOptions).map(option => (
                              <Dropdown.Item
                                key={option}
                                text={sortOptions[option]}
                                active={option === sort}
                                onClick={() => {
                                  setSort(option);
                                }}
                              />
                            )),
                          }}
                          placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT}
                        />
                        <div className="w-11" />
                      </FilterContainer>
                      <div
                        className={cx(
                          'absolute top-0 right-0 h-[44px] flex items-center transition-all',
                          !searchFocused ? 'w-11' : 'w-full',
                        )}
                      >
                        <SearchField
                          searching={Workspaces.isRefetching && !Workspaces.isFetchedAfterMount}
                          value={search}
                          onChange={v => {
                            setSearch(v);
                            if (isEmpty(v)) setSearchFocused(false);
                          }}
                          onFocus={() => setSearchFocused(true)}
                          onBlur={() => {
                            if (isEmpty(search)) {
                              setSearchFocused(false);
                            }
                          }}
                          debounce={500}
                          placeholder={t('Search Workspaces…')}
                          ref={searchInputRef}
                        />
                      </div>
                    </div>

                    <NavList>
                      {workspaces?.map?.(({ name, id, starred }) => (
                        <NavList.Item key={id} to={id} onClick={close}>
                          <div className="flex justify-between items-center pr-2">
                            {name}
                            <ChartWorkspaceStarButton
                              id={id}
                              size="xs"
                              value={starred}
                              controlled
                            />
                          </div>
                        </NavList.Item>
                      ))}
                    </NavList>
                    <IntersectionLoadMore
                      onVisible={Workspaces.fetchNextPage}
                      disabled={Workspaces.isFetchingNextPage || !Workspaces.hasNextPage}
                    />
                    {!readOnly && (
                      <AddPlaceholderButton
                        block
                        text="Add Workspace"
                        loading={mutations.createWorkspace.isPending}
                        onClick={() => setShowWorkspaceModal(true)}
                      />
                    )}
                  </div>
                </div>
              ),
            }}
          >
            {workspaces?.length ? (
              <Outlet />
            ) : (
              <UiState
                icon={{ component: Layout }}
                variant="zero"
                text="No workspaces created"
                center
              >
                {!readOnly && (
                  <PrimaryCTAButton
                    text="Add Workspace"
                    onClick={() => setShowWorkspaceModal(true)}
                    loading={mutations.createWorkspace.isPending}
                  />
                )}
              </UiState>
            )}
            <ChartsWorkspaceEdit
              isOpen={showWorkspaceModal}
              onClose={() => setShowWorkspaceModal(false)}
              onSubmit={actions.createWorkspace}
              isError={mutations.createWorkspace.isError}
              isLoading={mutations.createWorkspace.isPending}
            />
          </PageWrapper>
        }
      >
        <Route index element={<Navigate to={workspaces ? workspaces[0]?.id : undefined} />} />
        <Route
          path=":workspaceId/*"
          element={<ChartsWorkspace ref={workspaceRef} mutations={mutations} actions={actions} />}
        />
        <Route
          path="*"
          element={
            <UiState icon={{ component: Cursor }} variant="empty" text="No workspace selected" />
          }
        />
      </Route>
    </Routes>
  );
}
