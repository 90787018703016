import isFunction from 'lodash/isFunction';
import { useForm } from 'react-hook-form';

import { Modal, Card, Button } from '@optra/kit';

import ChartFiltersInput from 'components/chart-filters-input';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ValidationError from 'components/validation-error';
import { useInputFocus } from 'hooks';

function ChartSettingsForm({ onSubmit, workspace, chart, isLoading, isError }) {
  const isEditing = !!chart?.id;

  const {
    handleSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: chart?.name || '',
      config: workspace?.config ? workspace?.config : chart?.config || {},
    },
  });
  useInputFocus(setFocus, 'name');

  const handleOnSubmit = handleSubmit(form => {
    if (isFunction(onSubmit)) {
      const data = { ...form };
      if (isEditing) {
        data.id = chart.id;
      }
      onSubmit(data);
    }
  });

  return (
    <Modal.Window as="form" onSubmit={handleOnSubmit}>
      <Modal.Header heading={isEditing ? 'Edit Chart' : 'Add Chart'} />
      <Modal.Body className="space-y-3">
        {!!isError && <Message variant="danger">Something went wrong…</Message>}

        <Card variant="secondary">
          <div className="space-y-2">
            <Label htmlFor="name">Chart Name</Label>
            <Input
              type="text"
              {...register('name', { required: 'Please enter a name.' })}
              readOnly={isLoading}
            />
            <ValidationError errors={errors} name="name" />
          </div>
        </Card>

        <ChartFiltersInput control={control} name="config" />
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button type="submit" size="xl" loading={isLoading}>
          {isEditing ? 'Update' : 'Create'}
        </Button>
      </Modal.Footer>
    </Modal.Window>
  );
}

function ChartSettingsModal({ isOpen, onClose, onSubmit, workspace, chart, isLoading, isError }) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} renderWindow={false}>
      <ChartSettingsForm
        onSubmit={onSubmit}
        workspace={workspace}
        chart={chart}
        isLoading={isLoading}
        isError={isError}
      />
    </Modal>
  );
}

export default ChartSettingsModal;
