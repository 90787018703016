import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import { Icon, Text } from '@optra/kit';

import List from 'components/list';
import ListItem from 'components/list-item';
import Message from 'components/message';
import ModalBody from 'components/modal-body';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import { api, q } from 'config/api';
import actionIcon from 'lib/action-icon';
import { useActions } from 'queries';

export default function AddWorkflowAction() {
  useTitle('Add Action | Optra');
  const { workflowId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState();

  const actions = useActions();

  const qc = q.useQueryClient();
  const createWorkflowAction = q.useMutation({
    mutationFn: form =>
      api(
        `mutation($form: createWorkflowActionForm!) {
          workflowAction: createWorkflowAction(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess(r) {
      qc.invalidateQueries({ queryKey: ['workflow', workflowId] });
      qc.invalidateQueries({ queryKey: ['workflows'] });
    },
  });

  const handleSelectAction = actionId => {
    setError(null);
    createWorkflowAction.mutate(
      {
        workflowId,
        actionId,
      },
      {
        onSuccess(r) {
          navigate(`/workflows/${workflowId}/workflow-actions/${r.workflowAction?.id}/edit`, {
            state: {
              fromModal: true,
            },
          });
        },
        onError(err) {
          setError(err);
        },
      },
    );
  };

  const loading = actions.error || actions.isLoading || createWorkflowAction.isPending;

  return (
    <ModalInner>
      <ModalTitle title="Add Action" icon="PlusCircle" loading={loading} />
      <ModalBody>
        {actions.error && (
          <Message variant="danger" title="Couldn't Load Actions">
            {actions.error.message}
          </Message>
        )}
        {error && (
          <Message variant="danger" title="Couldn't Add Action">
            {error.message}
          </Message>
        )}
        <List loading={actions.isLoading}>
          {actions.data?.actions?.length > 0 &&
            actions.data?.actions?.map(action => (
              <ListItem
                key={action.id}
                onClick={() => handleSelectAction(action.id)}
                renderLeft={() => <Icon name={actionIcon(action.id)} color="gradient" />}
                renderRight={() => <Icon name="CaretRight" weight="line" />}
              >
                <Text>{action.name}</Text>
              </ListItem>
            ))}
        </List>
      </ModalBody>
    </ModalInner>
  );
}
