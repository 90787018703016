import { keys, uniqBy } from 'lodash';

import { Dropdown, Filter } from '@optra/kit';

import { useChartCtx } from '../context';

export default function ControlGranularity(props) {
  const {
    TIMEFRAME_BINS,
    helpers: { getChartConfig },
    actions: { setTimeframeBin },
  } = useChartCtx();

  const { timeframeBin } = getChartConfig();

  const text = timeframeBin ? TIMEFRAME_BINS[timeframeBin]?.name || 'Custom' : 'All';

  const options = uniqBy(
    keys(TIMEFRAME_BINS).map(key => TIMEFRAME_BINS[key]),
    'name',
  );

  return (
    <Filter
      label="Granularity"
      menu={{
        text,
        body: options.map(({ name, type }) => (
          <Dropdown.Item
            key={type}
            active={type === timeframeBin}
            text={name}
            onClick={() => setTimeframeBin(type)}
          />
        )),
      }}
      {...props}
    />
  );
}
