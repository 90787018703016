import flatMap from 'lodash/flatMap';
import isFunction from 'lodash/isFunction';
import reverse from 'lodash/reverse';
import { useForm, useController } from 'react-hook-form';

import { Modal, Card, Button, Table, Text } from '@optra/kit';

import ChartFiltersInput from 'components/chart-filters-input';
import Input from 'components/input';
import Label from 'components/label';
import Message from 'components/message';
import ValidationError from 'components/validation-error';
import { useInputFocus } from 'hooks';
import { useWorkspaces } from 'queries';

function SelectWorkspace(props) {
  const { data } = useWorkspaces();
  const workspaces = reverse(flatMap(data?.pages, page => page?.list?.data));

  const { rules, name, control, defaultValue = '' } = props;

  const controller = useController({
    control,
    rules,
    name,
    defaultValue,
  });

  const currentValue = controller.field?.value;

  if (!workspaces?.length) return null;

  return (
    <Card variant="secondary">
      <div className="space-y-2">
        <Label htmlFor="copyWorkspace">Duplicate Existing Workspace</Label>
        <Table>
          <Table.Body>
            {workspaces?.map?.(({ name, id }) => {
              return (
                <Table.Row
                  key={id}
                  hover
                  selectable
                  selected={currentValue === id}
                  onClick={() => {
                    if (currentValue === id) {
                      controller.field.onChange('');
                      return;
                    }
                    controller.field.onChange(id);
                  }}
                >
                  <Table.TD>
                    <Text size="sm">{name}</Text>
                  </Table.TD>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </Card>
  );
}

function ChartsWorkspaceForm({ onSubmit, workspace, isLoading, isError, isEditing }) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    setFocus,
    control,
  } = useForm({
    defaultValues: {
      name: workspace?.name || '',
      config: workspace?.config || {},
      ...(isEditing ? {} : { duplicateFromId: '' }),
    },
  });
  useInputFocus(setFocus, 'name');

  const handleOnSubmit = handleSubmit(form => {
    if (isFunction(onSubmit)) {
      onSubmit({
        ...form,
        ...(isEditing ? { id: workspace.id } : {}),
      });
    }
  });

  return (
    <Modal.Window as="form" onSubmit={handleOnSubmit}>
      <Modal.Header heading={isEditing ? 'Edit Workspace' : 'Add Workspace'} />
      <Modal.Body className="space-y-3">
        {!!isError && <Message variant="danger">Something went wrong...</Message>}

        <Card variant="secondary">
          <div className="space-y-2">
            <Label htmlFor="name">Workspace Name</Label>
            <Input
              type="text"
              {...register('name', { required: 'Please enter a name.' })}
              readOnly={isLoading}
            />
            <ValidationError errors={errors} name="name" />
          </div>
        </Card>

        {!isEditing && <SelectWorkspace control={control} name="duplicateFromId" />}

        {isEditing && <ChartFiltersInput control={control} name="config" />}
      </Modal.Body>
      <Modal.Footer className="justify-center">
        <Button type="submit" size="xl" loading={isLoading}>
          {isEditing ? 'Update' : 'Create'}
        </Button>
      </Modal.Footer>
    </Modal.Window>
  );
}

export default function ChartsWorkspaceEdit(props) {
  const { isOpen, onClose, onSubmit, workspace, isLoading, isError, isEditing } = props;
  return (
    <Modal isOpen={isOpen} onClose={onClose} renderWindow={false}>
      <ChartsWorkspaceForm
        onSubmit={onSubmit}
        workspace={workspace}
        isLoading={isLoading}
        isError={isError}
        isEditing={isEditing}
      />
    </Modal>
  );
}
