import classname from 'classnames';

import { StatusIndicator } from '@optra/kit';

import { useFeature } from 'components/feature';
import compute500 from 'images/compute500.png';
import compute550 from 'images/compute550.png';
import cx1000 from 'images/cx1000.png';
import cx2000 from 'images/cx2000.png';
import vz1000 from 'images/vz1000.png';
import vz5000 from 'images/vz5000.png';
import vz5100 from 'images/vz5100.png';
import vz6100 from 'images/vz6100.png';

const images = {
  compute500,
  compute550,
  cx1000,
  cx2000,
  vz1000,
  vz5000,
  vz5100,
  vz6100,
};

export function dimensions(size) {
  switch (size) {
    case 'xs':
      return {
        width: 24,
        height: 24,
      };
    case 'sm':
      return {
        width: 32,
        height: 32,
      };
    case 'md':
      return {
        width: 48,
        height: 48,
      };
    case 'lg':
      return {
        width: 64,
        height: 64,
      };
    case 'xl':
      return {
        width: 76,
        height: 76,
      };
    default:
      return {
        width: 48,
        height: 48,
      };
  }
}

export default function DeviceImage({
  hideStatus,
  model = 'vz1000',
  size = 'md',
  status = 'enabled',
  className,
  ...rest
}) {
  const deviceConnectionEnabled = useFeature('deviceConnection');

  return (
    <div
      className={classname(['', className])}
      style={{
        width: dimensions(size).width,
        height: dimensions(size).height,
      }}
      {...rest}
    >
      <div className="relative">
        <img
          alt={model}
          src={images[model]}
          width={dimensions(size).width}
          height={dimensions(size).height}
        />

        {!hideStatus && deviceConnectionEnabled && (
          <div
            style={{
              position: 'absolute',
              top: model === 'compute550' ? '52%' : '10%',
              left: model === 'compute550' ? '20%' : '50%',
              transform: 'translateX(-50%)',
            }}
          >
            <StatusIndicator status={status} />
          </div>
        )}
      </div>
    </div>
  );
}
