import { MagnifyingGlass } from '@phosphor-icons/react';
import cx from 'classnames';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isFunction from 'lodash/isFunction';
import isNull from 'lodash/isNull';
import { useEffect, useState, useRef } from 'react';

import {
  Button,
  Icon,
  Dropdown,
  DROPDOWN_MENU_PLACEMENT,
  Avatar,
  AvatarStack,
  DetailHeading,
  SearchField,
  UiState,
  useBreakpoint,
} from '@optra/kit';

import { useCurrentOrganization, useCurrentOrganizationMembers } from 'queries';

const normalize = wordsString => wordsString.trim().toLowerCase().split(' ');
const wordsIncludeWords = (base, search) => {
  if (!base || !search) return;
  const baseWords = normalize(base);
  const searchWords = normalize(search);
  return baseWords.some(bw => searchWords.some(sw => bw.includes(sw)));
};
const userMatchesSearch = (user, search) =>
  wordsIncludeWords(user.name, search) || wordsIncludeWords(user.email, search);

export default function ChartsWorkspaceAccessControls(props) {
  const { workspaceId, allowedUsers = [], onSubmit, disabled, mutation } = props;

  const [isDirty, setIsDirty] = useState(false);
  const [isPrivate, setIsPrivate] = useState(allowedUsers?.length > 0);
  const [search, setSearch] = useState('');
  const [allAllowedUsers, setAllAllowedUsers] = useState(allowedUsers || []);
  const [organization] = useCurrentOrganization();
  const isPersonal = organization.id === '$$NONE';

  const CurrentOrgMembers = useCurrentOrganizationMembers({ enabled: !isPersonal });
  const allUsers = CurrentOrgMembers.data;
  const isLoading = CurrentOrgMembers.isLoading || mutation.isPending;
  const isError = CurrentOrgMembers.isError || mutation.isError;

  const { thresholds } = useBreakpoint();
  const isMobile = !thresholds.lg;

  const cleanAllUsers = compact(filter(allUsers, ({ user }) => !isNull(user))).map(
    ({ user, roles }) => ({ ...user, roles }),
  );

  const userOptions = cleanAllUsers.map(user => ({
    ...user,
    active: includes(allAllowedUsers, user.email),
  }));
  const renderedUserOptions = !!search?.length
    ? filter(userOptions, user => userMatchesSearch(user, search))
    : userOptions;

  const selectedUsers = filter(userOptions, user => includes(allAllowedUsers, user.email));

  const handleOnClose = () => {
    if (search) setSearch('');
    if (isLoading) return;
    if (allAllowedUsers?.length < 1) setIsPrivate(false);
    if (isDirty && isFunction(onSubmit)) {
      onSubmit({ allowedUsers: allAllowedUsers });
    }
  };

  // Only reset when the workspace changes
  const prevWorkspaceIdRef = useRef();
  useEffect(() => {
    prevWorkspaceIdRef.current = workspaceId;
  });
  const prevWorkspaceId = prevWorkspaceIdRef.current;
  useEffect(() => {
    if (prevWorkspaceId !== workspaceId) {
      setAllAllowedUsers(allowedUsers || []);
      setIsPrivate(allowedUsers?.length > 0);
      setIsDirty(false);
    }
  }, [workspaceId, prevWorkspaceId, allowedUsers]);

  if (isPersonal) return null;
  if (disabled) return isPrivate ? <AvatarStack users={selectedUsers} /> : null;

  return (
    <Dropdown
      divide={false}
      scrolling={true}
      placement={
        isMobile ? DROPDOWN_MENU_PLACEMENT.BOTTOM_LEFT : DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT
      }
      onClose={handleOnClose}
      components={{
        button: ({ isOpen }) => (
          <span className="flex flex-nowrap items-center space-x-2">
            {isPrivate && !isMobile && <AvatarStack users={selectedUsers} />}
            <Button
              as="span"
              variant="secondary"
              size="sm"
              loading={isLoading}
              error={isError && 'Error loading access - please refresh'}
            >
              <span
                className={cx(
                  'flex flex-nowrap items-center justify-center space-x-2',
                  !isMobile && 'w-28',
                )}
              >
                <Icon
                  name={isPrivate ? 'LockKey' : 'Buildings'}
                  color={isPrivate ? 'primary' : undefined}
                  className={cx(!isPrivate && 'opacity-50')}
                  size="sm"
                  weight="duotone"
                />
                {!isMobile && <span>{isPrivate ? 'Private' : 'Public'}</span>}
                <Icon name={isOpen ? 'CaretUp' : 'CaretDown'} size="xs" />
              </span>
            </Button>
          </span>
        ),
        body: (
          <div className={cx('space-y-7 p-2 w-[22.5rem]', isPrivate ? 'pt-4' : 'py-4')}>
            <div className="px-3 space-y-4">
              <DetailHeading>Privacy</DetailHeading>
              <div className="space-y-2">
                <Dropdown.Item
                  icon={{
                    name: 'Buildings',
                    size: 'lg',
                    color: !isPrivate && 'neon',
                    weight: 'duotone',
                  }}
                  text="Public"
                  detail={`Anyone in ${organization.name} can access`}
                  onClick={e => {
                    e.preventDefault();
                    setAllAllowedUsers([]);
                    setIsPrivate(false);
                    setIsDirty(true);
                  }}
                  active={!isPrivate && !isLoading && !isError}
                  className="rounded-md bg-gray-100 dark:bg-white/10"
                />
                <Dropdown.Item
                  icon={{
                    name: 'LockKey',
                    size: 'lg',
                    color: !!isPrivate && 'neon',
                    weight: 'duotone',
                  }}
                  text="Private"
                  detail={
                    userOptions?.length
                      ? 'Only users you choose can access'
                      : 'No users with chart roles to select'
                  }
                  onClick={e => {
                    e.preventDefault();
                    setIsPrivate(true);
                    setIsDirty(true);
                    setAllAllowedUsers([]);
                  }}
                  disabled={!userOptions?.length}
                  active={!!isPrivate}
                  className="rounded-md bg-gray-100 dark:bg-white/10"
                />
              </div>
            </div>
            {!!(isPrivate && userOptions?.length) && (
              <div className="animate-fade-in space-y-4">
                <div className="px-3">
                  <DetailHeading>Users</DetailHeading>
                </div>
                <div className="px-3">
                  <SearchField
                    value={search}
                    onChange={setSearch}
                    onClear={() => setSearch('')}
                    placeholder="Search…"
                    // prevent keys from focusing items when trying to search
                    onKeyDown={e => e.stopPropagation()}
                  />
                </div>
                <div>
                  {renderedUserOptions?.length ? (
                    renderedUserOptions.map(user => (
                      <Dropdown.Item
                        key={user.email}
                        text={user.name}
                        detail={user.email}
                        components={{
                          before: <Avatar size="2xs" src={user.image} alt={user.name} />,
                        }}
                        active={user.active || user.roles.includes('admin')}
                        selectable
                        hoverSubtle
                        disabled={
                          isError ||
                          isLoading ||
                          (!user.active &&
                            (user.roles.includes('admin') ||
                              !['chartEditor', 'chartViewerOnly'].some(rr =>
                                user.roles.includes(rr),
                              )))
                        }
                        uppercase={false}
                        className="!rounded-md"
                        onClick={e => {
                          e.preventDefault();
                          allAllowedUsers.includes(user.email)
                            ? setAllAllowedUsers(users => users.filter(u => u !== user.email))
                            : setAllAllowedUsers(users => [...users, user.email]);
                          setIsDirty(true);
                        }}
                      />
                    ))
                  ) : (
                    <UiState
                      variant="empty"
                      icon={!!search ? { component: MagnifyingGlass } : undefined}
                      text={!!search ? 'No users found...' : 'No Users'}
                      size="sm"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        ),
      }}
    />
  );
}
