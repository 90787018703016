import cx from 'classnames';
import ceil from 'lodash/ceil';
import floor from 'lodash/floor';

import { useChartCtx, useChartType, useChartInterface } from '../context';

import ChartValue from './chart-value';

function Text({ value, angle: _angle, textAnchor, orientation, numValues, i, className }) {
  const {
    helpers: { formatNumber },
  } = useChartCtx();

  const isYAxis = orientation === 'left';
  const fontSize = 10;

  const rotationYTranslate = fontSize * i - (numValues * fontSize) / 2;
  const translate = Number.isNaN(parseFloat(rotationYTranslate))
    ? 0
    : parseFloat(rotationYTranslate);
  const angle = Number.isNaN(parseFloat(_angle)) ? 0 : parseFloat(_angle);

  const transform = isYAxis
    ? `translate(-5,-13)`
    : [`rotate(${angle})`, `translate(0, ${translate})`].join(',');

  return (
    <ChartValue
      className={cx('font-medium', className)}
      key={value}
      enable={!isYAxis}
      as="text"
      x={0}
      y={0}
      dy={16}
      transform={transform}
      textAnchor={isYAxis || !angle ? textAnchor : undefined}
      orientation={orientation}
      style={{ fontSize }}
      tooltip={false}
    >
      {isYAxis ? formatNumber(value) : value}
    </ChartValue>
  );
}

export default function ChartAxisTick(data) {
  const {
    configuration: { showInterface },
    helpers: { splitStringViaSymbol },
  } = useChartCtx();
  const {
    state: { isMobile },
    actions: { setShowDataTable },
    refs,
  } = useChartInterface();
  const {
    state: { chartData, selectedCategoryKey },
    actions: { selectCategory },
  } = useChartType();

  const {
    payload: { value },
    orientation,
    x,
    y,
  } = data;

  const valueArray = splitStringViaSymbol(value);

  const isYAxis = orientation === 'left';
  const isSelected = selectedCategoryKey === value;

  /////////////////////////////////////////////////////////
  // decide the interval of hiding/showing x-axis labels
  // based on number of labels and current width of chart
  /////////////////////////////////////////////////////////

  const graphWidth =
    (refs.chart?.current?.offsetWidth || 0) -
    ((isMobile ? 0 : refs.legend?.current?.offsetWidth) || 0);

  const categoryLabelPadding = 15; // combined left and right
  // ensure additional space is added for each stacking label
  const minCategoryLabelWidth = categoryLabelPadding + (valueArray?.length || 1) * 10;

  const { categories } = chartData;
  const maxAllowedCategories = floor(graphWidth / minCategoryLabelWidth);
  const hideAxisTick =
    !isSelected && !!(data?.index % ceil((categories?.length || 0) / maxAllowedCategories));

  const passedProps = {
    ...data,
    className: cx(
      'transition-opacity duration-100',
      !isYAxis && hideAxisTick && 'hidden',
      !!(!isYAxis && selectedCategoryKey && showInterface)
        ? isSelected
          ? 'fill-black dark:fill-white'
          : 'fill-gray opacity-40 hover:opacity-100'
        : 'fill-black dark:fill-white opacity-60',
    ),
  };

  const translate = [
    Number.isNaN(parseFloat(x)) ? 0 : parseFloat(x),
    Number.isNaN(parseFloat(y)) ? 0 : parseFloat(y),
  ].join(',');

  return (
    <g
      transform={`translate(${translate})`}
      className={cx(showInterface && 'cursor-pointer')}
      onClick={e => {
        if (!showInterface) return;
        selectCategory(value);
        setShowDataTable(true);
        e.stopPropagation();
      }}
    >
      {valueArray?.length ? (
        valueArray.map((value, i) => (
          <Text key={value} value={value} i={i} numValues={valueArray.length} {...passedProps} />
        ))
      ) : (
        <Text value={value} {...passedProps} />
      )}
    </g>
  );
}
