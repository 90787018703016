import cx from 'classnames';
import keys from 'lodash/keys';
import moment from 'moment';

import { Icon, DetailHeading, Dropdown, Text, DayPicker } from '@optra/kit';

import { useChartCtx } from '../context';
import _helpers from '../helpers';

function Calendar({ label, ...rest }) {
  return (
    <Dropdown.MenuBody divide={false}>
      <div className="p-2 pb-0">
        <div
          className={cx(
            'flex flex-col items-center',
            'text-center p-3 rounded-sm cursor-default',
            'bg-gray-200/30 dark:bg-black-900',
          )}
        >
          <DetailHeading>{label}</DetailHeading>
        </div>
      </div>
      <div className="p-5">
        <DayPicker {...rest} />
      </div>
    </Dropdown.MenuBody>
  );
}

export default function ControlDates() {
  const {
    helpers: { getChartConfig },
    actions: { setTimeframe },
    configuration: { readOnly },
    TIMEFRAMES,
    SMART_TIMEFRAMES,
  } = useChartCtx();

  const { start, end } = getChartConfig()?.timeframe || {};

  const startDate = _helpers.createMoment(start)?.toDate();
  const endDate = _helpers.createMoment(end)?.toDate();

  const QUICK_TIMEFRAMES = { ...SMART_TIMEFRAMES, ...TIMEFRAMES };

  return (
    <Dropdown
      disabled={!!readOnly}
      components={{
        button: (
          <span
            className={cx(
              'text-lg md:text-xl lg:text-2xl whitespace-nowrap font-medium',
              'inline-block',
              'hover:scale-105 duration-500 transition-transform',
              'flex flex-row flex-nowrap items-center space-x-2',
            )}
          >
            <Icon name="CalendarBlank" size="sm" weight="duotone" color="primary" />
            {QUICK_TIMEFRAMES[start] ? (
              <Text>
                {QUICK_TIMEFRAMES[start].name}{' '}
                {QUICK_TIMEFRAMES[start].description && (
                  <span className="text-primary font-light dark:font-thin">
                    {QUICK_TIMEFRAMES[start].description}
                  </span>
                )}
              </Text>
            ) : (
              <>
                <Text>
                  {_helpers.formatTime(start, 'MMMM Do')}{' '}
                  <span className="text-primary font-light dark:font-thin">
                    {_helpers.formatTime(start, 'YYYY')}
                  </span>
                </Text>
                <Icon name="ArrowRight" size="sm" weight="regular" color="gray" />
                <Text>
                  {_helpers.formatTime(end, 'MMMM Do')}{' '}
                  <span className="text-primary font-light dark:font-thin">
                    {_helpers.formatTime(end, 'YYYY')}
                  </span>
                </Text>
              </>
            )}
            {!readOnly && <Icon name="CaretDown" size="xs" weight="bold" color="gray" />}
          </span>
        ),
        body: (
          <div
            className={cx(
              'flex flex-row flex-nowrap items-stretch',
              'divide-x divide-light-fg-tertiary dark:divide-gray-200/10',
            )}
          >
            <Dropdown.MenuBody divide={false} scrolling className={cx('p-5 w-52')}>
              {keys(QUICK_TIMEFRAMES).map(time_key => {
                return (
                  <Dropdown.Item
                    key={time_key}
                    text={QUICK_TIMEFRAMES[time_key].name}
                    detail={QUICK_TIMEFRAMES[time_key].description}
                    className="rounded-md"
                    onClick={() => setTimeframe({ start: time_key })}
                    active={start === time_key}
                  />
                );
              })}
            </Dropdown.MenuBody>
            <Calendar
              label="Start"
              date={startDate}
              maxDate={endDate || moment().toDate()}
              onDateChange={date => {
                if (!date || !moment(date).isValid()) return;
                setTimeframe({ start: moment(date).startOf('day').toDate(), end: endDate });
              }}
            />
            <Calendar
              label="End"
              date={endDate}
              minDate={startDate}
              maxDate={moment().toDate()}
              onDateChange={date => {
                if (!date || !moment(date).isValid()) return;
                setTimeframe({ start: startDate, end: moment(date).endOf('day').toDate() });
              }}
            />
          </div>
        ),
      }}
    />
  );
}
