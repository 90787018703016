import cx from 'classnames';
import { Fragment } from 'react';
import {
  ResponsiveContainer,
  RadarChart,
  Bar,
  Line,
  Area,
  YAxis,
  XAxis,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ComposedChart,
  CartesianGrid,
} from 'recharts';

import { Fill } from '@optra/kit';

import { useChartCtx, useChartInterface } from '../context';

import ChartBase from './chart-base';
import ChartDataDetails from './chart-data-details';
import ChartTotal from './chart-total';

function Chart({ type, components, ...rest }) {
  const {
    state: { parsedQueryResults },
    helpers: { getChartConfig, parseVariableName },
    CHARTS,
  } = useChartCtx();
  const { refs } = useChartInterface();

  const { metrics = [] } = getChartConfig();

  const primaryMetric = metrics[0];

  if (!primaryMetric) return null;

  const {
    type: primaryType,
    metric: primaryMetricId,
    calculation: primaryCalculation,
  } = primaryMetric;

  const primaryMetricName = parseVariableName(primaryMetricId);

  if (primaryType === CHARTS.columns.type) {
    return (
      <Fill ref={refs.chart} scroll className="animate-fade-in">
        <ChartDataDetails naked />
      </Fill>
    );
  }

  if (primaryType === CHARTS.number.type) {
    return (
      <Fill ref={refs.chart} centerItems className="animate-fade-in">
        <ChartTotal
          heading={primaryMetricName}
          total={parsedQueryResults.values?.metrics[primaryMetricName]?.total}
          calculation={primaryCalculation}
        />
      </Fill>
    );
  }

  const isRadarChart = primaryType === CHARTS.radar.type;

  return (
    <Fill ref={refs.chart} className="animate-fade-in">
      <ResponsiveContainer
        width="100%"
        height="100%"
        className="overflow-hidden absolute top-0 left-0 w-full h-full"
      >
        <ChartBase
          components={{
            ...components,
            main: isRadarChart ? RadarChart : ComposedChart,
          }}
          {...rest}
        >
          {({
            groups,
            groupKeys,
            groupProps,
            id,
            AREA_CHART_OFFSETS,
            AREA_CHART_STOP_OPACITIES,
            STROKE,
            xAxisProps: _xAxisProps,
            yAxisProps: _yAxisProps,
          }) => {
            // ensure there is always an X and Y axis visible;
            let visibleIndex = -1;
            return (
              <>
                {isRadarChart ? (
                  <>
                    <PolarAngleAxis {..._xAxisProps} angle={0} />
                    <PolarRadiusAxis {..._yAxisProps} />
                    <PolarGrid stroke={STROKE} />
                  </>
                ) : (
                  <CartesianGrid vertical={false} strokeDasharray="2 3" stroke={STROKE} />
                )}
                <defs>
                  {groupKeys.map((groupKey, i) => {
                    if (!groupKey) return null;

                    const { metric = {} } = groups[groupKey];
                    const { color } = metric || {};

                    return (
                      <linearGradient key={groupKey} id={`${i}_${id}`} x1="0" y1="0" x2="0" y2="1">
                        <stop
                          offset={AREA_CHART_OFFSETS[0]}
                          stopColor={color}
                          stopOpacity={AREA_CHART_STOP_OPACITIES[0]}
                        />
                        <stop
                          offset={AREA_CHART_OFFSETS[1]}
                          stopColor={color}
                          stopOpacity={AREA_CHART_STOP_OPACITIES[1]}
                        />
                      </linearGradient>
                    );
                  })}
                </defs>
                {groupKeys.map((groupKey, i) => {
                  if (!groupKey) return null;

                  visibleIndex++;

                  const hideAxis = !!visibleIndex;
                  const hideXAxis = hideAxis || components?.xAxis === null;
                  const hideYAxis = hideAxis || components?.yAxis === null;

                  const { metric = {} } = groups[groupKey];
                  const { type, metric: metricId, color } = metric || {};

                  const yAxisProps = {
                    hide: hideYAxis,
                    yAxisId: metricId,
                    className: cx(hideYAxis && 'opacity-0'),
                    ..._yAxisProps,
                  };

                  const xAxisProps = {
                    hide: hideXAxis,
                    xAxisId: metricId,
                    className: cx(hideXAxis && 'opacity-0'),
                    ..._xAxisProps,
                  };

                  const sharedChartElemProps = {
                    dataKey: groupKey,
                    ...groupProps,
                  };

                  const key = `${groupKey}_${i}`;

                  if (isRadarChart) {
                    return (
                      <Fragment key={key}>
                        <Radar
                          {...sharedChartElemProps}
                          yAxisId={yAxisProps.yAxisId}
                          xAxisId={xAxisProps.xAxisId}
                          fill={color}
                          fillOpacity={0.3}
                          stroke={color}
                          strokeWidth={2}
                        />
                      </Fragment>
                    );
                  }

                  switch (type) {
                    case CHARTS.bar.type:
                      return (
                        <Fragment key={key}>
                          <YAxis {...yAxisProps} />
                          <XAxis {...xAxisProps} />
                          <Bar
                            {...sharedChartElemProps}
                            yAxisId={yAxisProps.yAxisId}
                            xAxisId={xAxisProps.xAxisId}
                            fill={color}
                          />
                        </Fragment>
                      );
                    case CHARTS['bar-stacked'].type:
                      return (
                        <Fragment key={key}>
                          <YAxis {...yAxisProps} />
                          <XAxis {...xAxisProps} />
                          <Bar
                            {...sharedChartElemProps}
                            yAxisId={yAxisProps.yAxisId}
                            xAxisId={xAxisProps.xAxisId}
                            stackId={xAxisProps.xAxisId}
                            fill={color}
                          />
                        </Fragment>
                      );
                    case CHARTS.line.type:
                      return (
                        <Fragment key={key}>
                          <YAxis {...yAxisProps} />
                          <XAxis {...xAxisProps} />
                          <Line
                            {...sharedChartElemProps}
                            yAxisId={yAxisProps.yAxisId}
                            xAxisId={xAxisProps.xAxisId}
                            stroke={color}
                            strokeWidth={2}
                          />
                        </Fragment>
                      );
                    case CHARTS.area.type:
                      return (
                        <Fragment key={key}>
                          <YAxis {...yAxisProps} />
                          <XAxis {...xAxisProps} />
                          <Area
                            {...sharedChartElemProps}
                            yAxisId={yAxisProps.yAxisId}
                            xAxisId={xAxisProps.xAxisId}
                            stroke={color}
                            fillOpacity={1}
                            fill={`url(#${i}_${id})`}
                            strokeWidth={2}
                          />
                        </Fragment>
                      );
                    case CHARTS['area-stacked'].type:
                      return (
                        <Fragment key={key}>
                          <YAxis {...yAxisProps} />
                          <XAxis {...xAxisProps} />
                          <Area
                            {...sharedChartElemProps}
                            yAxisId={yAxisProps.yAxisId}
                            xAxisId={xAxisProps.xAxisId}
                            stackId={xAxisProps.xAxisId}
                            stroke={color}
                            fillOpacity={1}
                            fill={`url(#${i}_${id})`}
                            strokeWidth={2}
                          />
                        </Fragment>
                      );
                    default:
                      return (
                        <Fragment key={key}>
                          <YAxis {...yAxisProps} />
                          <XAxis {...xAxisProps} />
                          <Line
                            {...sharedChartElemProps}
                            yAxisId={yAxisProps.yAxisId}
                            xAxisId={xAxisProps.xAxisId}
                            stroke={color}
                            strokeWidth={2}
                          />
                        </Fragment>
                      );
                  }
                })}
              </>
            );
          }}
        </ChartBase>
      </ResponsiveContainer>
    </Fill>
  );
}

export default Chart;
