import flatMap from 'lodash/flatMap';

import IntersectionLoadMore from 'components/intersection-load-more';
import WorkflowCard from 'components/workflow-card';
import WorkflowSkillListItem from 'components/workflow-skill-list-item';
import { useHasRoles } from 'hooks';
import { useWorkflowsSkills } from 'queries';

export default function WorkflowSkills({
  workflowId,
  shouldFetch,
  expanded = true,
  deleting = false,
}) {
  const [canEdit] = useHasRoles(['admin', 'workflowEditor', 'workflowDeployer']);

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useWorkflowsSkills(
    workflowId,
    {
      list: {
        limit: 12,
      },
    },
    { enabled: !!workflowId && shouldFetch },
  );
  const count = data?.pages?.[0]?.workflow?.list?.count;
  const skills = flatMap(data?.pages, page => page?.workflow?.list?.data);
  const workflowSkills = skills?.filter(workflowSkill => !!workflowSkill);

  return (
    <WorkflowCard
      icon="Cube"
      heading="Skills"
      plusUrl={`/workflows/${workflowId}/workflow-skills/add`}
      count={count}
      loading={isLoading}
      disabled={!canEdit || deleting}
      expanded={expanded}
    >
      {workflowSkills?.map?.(workflowSkill => (
        <WorkflowSkillListItem
          key={workflowSkill.id}
          id={workflowSkill.id}
          workflowId={workflowId}
          name={workflowSkill.name || workflowSkill.skill?.name}
          icon={workflowSkill.skill?.icon}
          iconUrl={workflowSkill.skill?.iconUrl}
          color={workflowSkill.skill?.color}
          developerName={workflowSkill.skill?.developerProfile?.name}
          dirty={!workflowSkill.synchronized && !workflowSkill.isSynchronizing}
          updateAvailable={workflowSkill.updateAvailable}
          deleted={workflowSkill.deleted}
          disabled={workflowSkill.disabled.disabled}
          disabledReasons={workflowSkill.disabled.reasons}
          deleting={deleting}
        />
      ))}

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </WorkflowCard>
  );
}
