import { api, q } from 'config/api';

export default function useWorkspace(id, overrides) {
  return q.useQuery({
    queryKey: ['workspace', id],
    queryFn: () =>
      api(
        `query workspace($id: ID!) {
          workspace(id: $id) {
            id
            name
            starred
            layout
            config {
              workflowIds
              deviceIds
              skillIds
              tagIds
              locationIds
            }
            workspaceCharts {
              data {
                id
                layout
                chart {
                  id
                  name
                  config {
                    metrics {
                      type
                    }
                  }
                }
              }
            }
            allowedUsers {
              data {
                email
              }
            }
          }
        }`,
        { id },
      ),
    enabled: !!id,
    ...overrides,
  });
}
