import { ChartLine, Cursor } from '@phosphor-icons/react';
import cx from 'classnames';
import compact from 'lodash/compact';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useState, useImperativeHandle, forwardRef } from 'react';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';

import {
  PageWrapper,
  AddPlaceholderButton,
  PrimaryCTAButton,
  Button,
  Icon,
  Dropdown,
  DROPDOWN_MENU_PLACEMENT,
  UiState,
} from '@optra/kit';

import ChartSettingsModal from 'components/chart-settings-modal';
import ChartWorkspaceStarButton from 'components/chart-workspace-star-button';
import ChartsWorkspaceAccessControls from 'components/charts-workspace-access-controls';
import {
  ChartsWorkspaceCardList,
  CARD_GRID,
  CARD_GAP,
} from 'components/charts-workspace-card-list';
import ChartsWorkspaceDuplicate from 'components/charts-workspace-duplicate';
import ChartsWorkspaceEdit from 'components/charts-workspace-edit';
import Modal from 'components/modal';
import PinPageButton from 'components/pin-page-button';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import ChartDetails, {
  CALCULATIONS,
  CHARTS,
  TIMEFRAMES,
  TIMEFRAME_BINS,
} from 'modals/chart-details/chart-details';
import { useWorkspace } from 'queries';

const DEFAULT_CHART = {
  name: '',
  query: '',
  type: CHARTS.bar.type,
  config: {
    timeframe: {
      start: TIMEFRAMES['30d'].type,
    },
    timeframeBin: TIMEFRAME_BINS['1h'].type,
    calculation: CALCULATIONS.AVG.type,
    xAxis: ['time'],
  },
};

export default forwardRef(function ChartsWorkspace(props, ref) {
  const { mutations: workspacesMutations, actions: workspacesActions } = props;
  const { workspaceId } = useParams();
  const navigate = useNavigate();

  const [readOnly, loadingRoles] = useHasRoles(['chartViewerOnly'], true);
  const [isAdmin, loadingIsAdmin] = useHasRoles(['admin']);

  const { data, isLoading: workspaceLoading, isError: workspaceError } = useWorkspace(workspaceId);

  const [isLoading, isError] = [workspaceLoading || loadingRoles || loadingIsAdmin, workspaceError];

  const workspaceCharts = compact(flatMap(data, data => data?.workspaceCharts?.data));
  const workspaceAllowedUsers = compact(
    flatMap(data, data => data?.allowedUsers?.data?.map(u => u.email)),
  );
  const workspaceCardOrder = compact([...flatMap(data, data => data?.layout?.order)]);

  const orderedWorkspaceCharts = uniqBy(
    [
      ...compact(
        workspaceCardOrder?.length
          ? workspaceCardOrder.map(id => find(workspaceCharts, ['id', id]))
          : workspaceCharts,
      ),
      ...workspaceCharts,
    ],
    'id',
  );

  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      workspaceModal: {
        open: () => setShowWorkspaceModal(true),
        close: () => setShowWorkspaceModal(false),
      },
      settingsModal: {
        open: () => setShowSettingsModal(true),
        close: () => setShowSettingsModal(false),
      },
      duplicateModal: {
        open: () => setShowDuplicateModal(true),
        close: () => setShowDuplicateModal(false),
      },
    }),
    [],
  );

  const qc = q.useQueryClient();

  const helpers = {
    assignWorkspaceCard: (old, update) => {
      if (!old) {
        return;
      }
      if (!update) {
        return old;
      }
      return {
        ...old,
        ...update,
        layout: {
          ...old.layout,
          ...update.layout,
        },
      };
    },
    mergeWorkspaceCards: (old = [], updates = []) => {
      const updatedItems = updates.map(item =>
        helpers.assignWorkspaceCard(find(old, ['id', item.id]), item),
      );
      return uniqBy(compact([...updatedItems, ...updates, ...old]), 'id');
    },
    assignWorkspace: (old, updates) => {
      if (!old) {
        return;
      }
      if (!updates) {
        return old;
      }
      return {
        ...old,
        ...updates,
        layout: {
          ...old.layout,
          ...updates.layout,
        },
        workspaceCharts: {
          ...old.workspaceCharts,
          ...updates.workspaceCharts,
          data: helpers.mergeWorkspaceCards(
            old.workspaceCharts?.data,
            updates.workspaceCharts?.data,
          ),
        },
      };
    },
  };

  const mutations = {
    updateWorkspace: q.useMutation({
      mutationFn: form =>
        api(
          `mutation updateWorkspace($form: updateWorkspaceForm!) {
            updateWorkspace(form: $form) {
              id
              layout
            }
          }`,
          { form },
        ),
      onMutate: async workspace => {
        await qc.cancelQueries({ queryKey: ['workspace', workspaceId] });

        qc.setQueryData(['workspace', workspaceId], old => ({
          workspace: helpers.assignWorkspace(old.workspace, workspace),
        }));

        return { workspace };
      },
      onSettled: () => {
        qc.invalidateQueries({ queryKey: ['workspace', workspaceId] });
        qc.invalidateQueries({ queryKey: ['workspaces'] });
        data?.workspace?.workspaceCharts?.data?.forEach?.(wc => {
          qc.invalidateQueries({ queryKey: ['chart', wc.chart.id] });
        });
        setShowWorkspaceModal(false);
      },
    }),
    createWorkspaceChart: q.useMutation({
      mutationFn: form =>
        api(
          `mutation createWorkspaceChart($form: createWorkspaceChartForm!) {
            createWorkspaceChart(form: $form) {
              id
              layout
              chart {
                id
              }
            }
          }`,
          { form },
        ),
      onSuccess: () => qc.invalidateQueries({ queryKey: ['workspace'] }),
    }),
    updateWorkspaceChart: q.useMutation({
      mutationFn: form =>
        api(
          `mutation updateWorkspaceChart($form: updateWorkspaceChartForm!) {
            updateWorkspaceChart(form: $form) {
              id,
              layout
            }
          }`,
          { form },
        ),
      onMutate: async newWorkspaceChart => {
        await qc.cancelQueries({ queryKey: ['workspace', workspaceId] });

        qc.setQueryData(['workspace', workspaceId], old => ({
          workspace: helpers.assignWorkspace(old.workspace, {
            workspaceCharts: { data: [newWorkspaceChart] },
          }),
        }));

        return { newWorkspaceChart };
      },
      onSettled: () => qc.invalidateQueries({ queryKey: ['workspace', workspaceId] }),
    }),
  };

  const actions = {
    updateWorkspace: form => {
      if (readOnly) return;
      if (!form) return;
      mutations.updateWorkspace.mutate({
        id: workspaceId,
        ...form,
      });
    },
    createChart: data => {
      if (readOnly) return;
      mutations.createWorkspaceChart.mutate(
        {
          workspaceId,
          chart: {
            ...DEFAULT_CHART,
            ...data,
          },
        },
        {
          onSuccess: ({ createWorkspaceChart }) => {
            const { chart, id } = createWorkspaceChart;
            setShowSettingsModal(false);
            navigate(`chart/${chart.id}/edit`);
            actions.updateChartOrder([id, ...(workspaceCardOrder || [])]);
          },
        },
      );
    },
    updateChartOrder: ids => {
      if (readOnly) return;
      if (!Array.isArray(ids)) return;
      actions.updateWorkspace({
        layout: {
          order: uniq(compact(ids.map(id => (find(workspaceCharts, ['id', id]) ? id : undefined)))),
        },
      });
    },
    updateWorkspaceChart: form => {
      if (readOnly) return;
      if (!form) return;
      mutations.updateWorkspaceChart.mutate(form);
    },
  };

  const { workspace } = data || {};

  if (!isLoading && !workspace) {
    return <UiState icon={{ component: Cursor }} variant="empty" text="Workspace Not Found" />;
  }

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <PageWrapper
              title={workspace?.name}
              heading={
                <>
                  <span>{workspace?.name}</span>
                  <ChartWorkspaceStarButton id={workspaceId} />
                </>
              }
              components={{
                actions: (
                  <>
                    <PinPageButton />
                    {!readOnly && (
                      <>
                        <ChartsWorkspaceAccessControls
                          workspaceId={workspace?.id}
                          disabled={!isAdmin}
                          // TODO: Bring this logic inside the scope it is used in
                          allowedUsers={workspaceAllowedUsers}
                          onSubmit={actions.updateWorkspace}
                          mutation={mutations.updateWorkspace}
                        />
                        <Dropdown
                          components={{
                            button: ({ open }) => (
                              <Button as="span" icon="Gear" size="sm" variant="secondary">
                                <Icon
                                  name={open ? 'CaretUp' : 'CaretDown'}
                                  size="xs"
                                  className="ml-1"
                                />
                              </Button>
                            ),
                            body: (
                              <>
                                <Dropdown.Item
                                  icon="PencilSimple"
                                  text="Settings"
                                  onClick={() => setShowWorkspaceModal(true)}
                                />
                                <Dropdown.Item
                                  icon="CopySimple"
                                  text="Duplicate"
                                  onClick={() => setShowDuplicateModal(true)}
                                />
                                <Dropdown.Item
                                  icon="TrashSimple"
                                  text={
                                    workspacesMutations.removeWorkspace.isPending
                                      ? 'Deleting...'
                                      : 'Delete'
                                  }
                                  loading={workspacesMutations.removeWorkspace.isPending}
                                  onClick={e => {
                                    workspacesActions.removeWorkspace(workspace?.id);
                                    e.preventDefault(); // keep dropdown open while deleting
                                  }}
                                />
                              </>
                            ),
                          }}
                          placement={DROPDOWN_MENU_PLACEMENT.BOTTOM_RIGHT}
                        />
                        {!!orderedWorkspaceCharts?.length && (
                          <PrimaryCTAButton
                            onClick={() => setShowSettingsModal(true)}
                            loading={mutations.createWorkspaceChart.isPending}
                            text="Add Chart"
                          />
                        )}
                      </>
                    )}
                  </>
                ),
              }}
              inline={true}
              loading={isLoading}
              error={isError}
            >
              {!!orderedWorkspaceCharts?.length ? (
                <ChartsWorkspaceCardList
                  workspaceCharts={orderedWorkspaceCharts}
                  actions={actions}
                />
              ) : (
                <div className={cx(CARD_GRID, CARD_GAP)}>
                  {readOnly ? (
                    <UiState
                      variant="empty"
                      icon={{
                        component: ChartLine,
                      }}
                      text="No Charts"
                      className="animate-fade-in"
                      center
                    />
                  ) : (
                    <AddPlaceholderButton
                      text="Add Chart"
                      onClick={() => setShowSettingsModal(true)}
                      loading={mutations.createWorkspaceChart.isPending}
                      className={cx('col-span-6 md:col-span-3 h-80')}
                      large
                    />
                  )}
                </div>
              )}

              {!readOnly && (
                <>
                  <ChartsWorkspaceEdit
                    workspace={workspace}
                    isOpen={showWorkspaceModal}
                    onClose={() => setShowWorkspaceModal(false)}
                    onSubmit={actions.updateWorkspace}
                    isError={mutations.updateWorkspace.isError}
                    isLoading={mutations.updateWorkspace.isPending}
                    isEditing
                  />
                  <ChartsWorkspaceDuplicate
                    workspace={workspace}
                    isOpen={showDuplicateModal}
                    onClose={() => setShowDuplicateModal(false)}
                    onSubmit={workspacesActions.duplicateWorkspace}
                    isError={workspacesMutations.duplicateWorkspace.isError}
                    isLoading={workspacesMutations.duplicateWorkspace.isPending}
                  />
                  <ChartSettingsModal
                    isOpen={showSettingsModal}
                    onClose={() => setShowSettingsModal(false)}
                    onSubmit={data => actions.createChart(data)}
                    isError={mutations.createWorkspaceChart.isError}
                    isLoading={mutations.createWorkspaceChart.isPending}
                    workspace={workspace}
                  />
                </>
              )}
            </PageWrapper>
          }
        />

        <Route
          path="chart/:chartId/edit"
          element={
            <Modal cover>
              <ChartDetails />
            </Modal>
          }
        />
      </Routes>
    </>
  );
});
