import cx from 'classnames';
import { useRef, Fragment } from 'react';

import { Toggle, Icon, Tooltip, Text, ButtonWrap, Spinner } from '@optra/kit';

import { useChartCtx, useChartInterface, useChartType } from '../context';
import useChartData from '../use-chart-data';

import ChartDataTable from './chart-data-table';
import ChartValue from './chart-value';

export default function ChartDataDetails({ naked }) {
  const {
    helpers: { splitStringViaSymbol },
    configuration: { readOnly },
    TIME_DIMENSIONS,
    CONSTANTS: { PANEL_BG, PADDING, Y_PADDING, Y_SPACING, BORDER_COLOR },
  } = useChartCtx();
  const {
    state: {
      isMobile,
      ui: { showAllDetailDimensions, showDetailLineChart },
    },
    actions: { setShowDataTable, setShowAllDetailDimensions, setShowDetailLineChart },
  } = useChartInterface();
  const {
    state: { selectedCategoryKey },
    actions: { selectCategory },
  } = useChartType();

  const tableScrollRef = useRef(null);

  const {
    query,
    orderByKey,
    orderDirection,
    toggleOrderKey,
    parsedData: { columns, rows },
    limit,
    error,
    isError,
    isLoading,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    fetchNextPage,
  } = useChartData();

  const dataTableProps = {
    naked,
    columns,
    rows,
    limit,
    orderByKey,
    orderDirection,
    onColumnClick: toggleOrderKey,
    error,
    isError,
    isLoading,
    isFetchingNextPage,
    isRefetching,
    hasNextPage,
    fetchNextPage,
    cellWrap: !selectedCategoryKey,
    selectedCategoryKey,
    tableScrollRef,
  };

  const headingArray = splitStringViaSymbol(selectedCategoryKey);

  if (naked) {
    return <ChartDataTable {...dataTableProps} className={cx(Y_PADDING, 'pt-0 lg:pt-0')} />;
  }

  return (
    <div
      className={cx(
        'ChartDataDetails',
        'absolute inset-0 w-full h-full',
        'cursor-default',
        'flex flex-col',
        'rounded-t-lg lg:rounded-t-2xl',
        'border',
        PADDING,
        'pb-0 lg:pb-0',
        'divide-y',
        Y_SPACING,
        PANEL_BG,
        BORDER_COLOR,
        isLoading && 'animate-pulse',
      )}
    >
      <div className={cx('flex-0', 'flex', 'flex-row items-center justify-between', 'space-x-5')}>
        <div className={cx('flex-1', 'flex flex-nowrap items-center space-x-3')}>
          <div className="inline-flex items-center space-x-2">
            {!!isLoading ? (
              <Spinner color="gradient" />
            ) : (
              <Icon name="ListMagnifyingGlass" weight="duotone" color="primary" />
            )}
            <Text className="text-lg lg:text-xl font-medium">
              {!!selectedCategoryKey
                ? headingArray.map((heading, i) => {
                    return (
                      <Fragment key={`${i}_${heading}`}>
                        {!!i && ', '}
                        <ChartValue>{heading}</ChartValue>
                      </Fragment>
                    );
                  })
                : 'Details'}
            </Text>
          </div>
          {!!selectedCategoryKey && (
            <ButtonWrap
              onClick={() => selectCategory(undefined)}
              className="inline-flex items-center space-x-1 flex-nowrap !ml-4 mt-1"
            >
              <Text className="text-xs underline font-light underline-offset-2 text-primary decoration-primary/40">
                Clear Selection
              </Text>
            </ButtonWrap>
          )}
        </div>
        {!isMobile && (
          <div className={cx('flex-0', 'flex flex-nowrap items-center space-x-5')}>
            {!!selectedCategoryKey && (
              <>
                {(orderByKey === TIME_DIMENSIONS.time.type ||
                  orderByKey === TIME_DIMENSIONS.tzTime.type) && (
                  <Toggle
                    label="Detail Chart"
                    labelPlacement="left"
                    checked={showDetailLineChart}
                    onChange={(e, checked) => setShowDetailLineChart(checked)}
                    className="animate-fade-in"
                  />
                )}
                <Toggle
                  label="All Columns"
                  labelPlacement="left"
                  checked={showAllDetailDimensions}
                  onChange={(e, checked) => setShowAllDetailDimensions(checked)}
                  className="animate-fade-in"
                />
              </>
            )}
            {!readOnly && (
              <Tooltip
                label={query}
                className="whitespace-pre font-mono text-black-900 dark:text-primary"
                style={{ textAlign: 'left' }}
              >
                <Icon name="TerminalWindow" weight="duotone" color="primary" size="sm" />
              </Tooltip>
            )}
          </div>
        )}
        <div className="flex-0">
          <Tooltip label="Dismiss">
            <ButtonWrap
              onClick={() => {
                setShowDataTable(false);
                selectCategory(undefined);
              }}
              className="opacity-30 hover:opacity-80"
            >
              <Icon name="XCircle" weight="light" size="lg" />
            </ButtonWrap>
          </Tooltip>
        </div>
      </div>
      <div
        ref={tableScrollRef}
        className={cx('flex-1', 'relative overflow-auto scroll-bar-hidden')}
      >
        <ChartDataTable {...dataTableProps} />
      </div>
    </div>
  );
}
