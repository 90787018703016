import { MagnifyingGlass } from '@phosphor-icons/react';
import cx from 'classnames';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import keys from 'lodash/keys';
import noop from 'lodash/noop';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';

import { DetailHeading, Dropdown, UiState, Icon, SearchField } from '@optra/kit';

import { useFeature } from 'components/feature';

import { useChartCtx } from '../context';
import { CALCULATIONS } from '../data';

function MetricList({
  list = [],
  value,
  disableVariables,
  variableAlreadyInUse,
  variableIncompatible,
  isMetricTablePopulated,
  onClick,
}) {
  const [searchFocused, setSearchFocused] = useState(false);

  const [search, setSearch] = useState('');
  const filteredList = filter(list, ({ name, id }) => {
    const searchableValue = `${name} - ${id}`?.toLowerCase();
    return includes(searchableValue, search.toLowerCase());
  }).map(item => ({
    ...item,
    populated: !!isMetricTablePopulated(item.id),
  }));
  const orderedList = orderBy(filteredList, ['db', 'populated', 'name'], ['asc', 'desc', 'asc']);

  return (
    <Dropdown.MenuBody
      divide={false}
      scrolling
      className="p-5"
      // prevent a-z keys from focusing items when trying to search
      onKeyDown={e => !!searchFocused && e.stopPropagation()}
    >
      <DetailHeading className="px-3">METRICS</DetailHeading>
      <div className="p-4">
        <SearchField
          value={search}
          onChange={setSearch}
          onFocus={() => setSearchFocused(true)}
          onBlur={() => setSearchFocused(false)}
          placeholder="Search Metrics…"
          debounce={10}
        />
      </div>
      {!orderedList?.length ? (
        <UiState variant="empty" text="No metrics..." icon={{ component: MagnifyingGlass }} />
      ) : (
        orderedList.map(({ id, name, db, table, populated }, i) => {
          const active = id === value;
          const inUse = disableVariables && variableAlreadyInUse(id);
          const incompatibleTable = disableVariables && variableIncompatible(id);
          const disabled = inUse || incompatibleTable;

          return (
            <Dropdown.Item
              key={`${id}_${i}`}
              text={name}
              title={incompatibleTable ? 'Tables do not match.' : undefined}
              detail={
                <span className="flex flex-nowrap items-center space-x-2">
                  <span className="flex flex-nowrap items-center space-x-1">
                    <Icon
                      name={incompatibleTable ? 'Lock' : 'Database'}
                      weight="regular"
                      size="xs"
                      className="opacity-60"
                    />
                    <span>
                      {db}.{table}
                    </span>
                  </span>
                  {!populated && (
                    <>
                      <span className="opacity-50 mx-2">•</span>
                      <span className="flex flex-nowrap items-center space-x-1">No Data</span>
                    </>
                  )}
                </span>
              }
              uppercase={false}
              small={false}
              className={cx(
                'rounded-md',
                (incompatibleTable || (disabled && !active)) && 'opacity-30',
              )}
              disabled={disabled}
              onClick={disabled ? null : () => onClick(id)}
              active={active}
            />
          );
        })
      )}
    </Dropdown.MenuBody>
  );
}

function ChartList({ list = [], value, disableNonComposeCharts, onClick }) {
  return (
    <Dropdown.MenuBody divide={false} scrolling className="p-5">
      <DetailHeading className="px-3 mb-4">CHART</DetailHeading>
      {list.map(({ name, type, compose }, i) => {
        const active = type === value;
        const disabled = disableNonComposeCharts && !compose;
        return (
          <Dropdown.Item
            key={`${type}_${i}`}
            text={name}
            uppercase={false}
            disabled={disabled}
            className="rounded-md"
            onClick={() => onClick(type)}
            active={active}
          />
        );
      })}
    </Dropdown.MenuBody>
  );
}

function CalculationList({ value, onClick }) {
  return (
    <Dropdown.MenuBody divide={false} scrolling className="p-5">
      <DetailHeading className="px-3 mb-4">CALCULATION</DetailHeading>
      {Object.entries(CALCULATIONS).map(([type, { name }]) => {
        const active = type === value;
        return (
          <Dropdown.Item
            key={type}
            text={name}
            uppercase={false}
            className="rounded-md"
            onClick={() => onClick(type)}
            active={active}
          />
        );
      })}
    </Dropdown.MenuBody>
  );
}

function MetricDropdownMenu(props) {
  const {
    onSelect = noop,
    value,
    showCalculations = true,
    showCharts = true,
    disableVariables = true,
    disableNonComposeCharts = false,
  } = props;

  const {
    queries,
    helpers: {
      getUsedVariables,
      getChartConfigMetrics,
      getChartTableMetrics,
      variableTableMatchesConfig,
      isMetricTablePopulated,
    },
    CHARTS,
  } = useChartCtx();

  const usedVariables = getUsedVariables();
  const allSkillOutputsEnabled = useFeature('allSkillOutputs', 'global');

  const { isLoading, isRefetching } = queries.chartConfigMetrics;
  const loading = isLoading || isRefetching;
  const chartMetrics = allSkillOutputsEnabled ? getChartTableMetrics() : getChartConfigMetrics();
  const variableAlreadyInUse = variable => includes(usedVariables, variable);
  const variableIncompatible = variable => !variableTableMatchesConfig(variable);

  if (loading) return <UiState margin={false} className="m-10" />;

  if (!Array.isArray(chartMetrics))
    return <UiState variant="error" margin={false} className="m-10" />;

  return (
    <div
      className={cx(
        'flex flex-row flex-nowrap',
        'divide-x divide-light-fg-tertiary dark:divide-gray-200/10',
      )}
    >
      <MetricList
        list={chartMetrics}
        disableVariables={disableVariables}
        variableAlreadyInUse={variableAlreadyInUse}
        variableIncompatible={variableIncompatible}
        isMetricTablePopulated={isMetricTablePopulated}
        value={value?.metric}
        onClick={metric => {
          if (disableVariables && variableAlreadyInUse(metric)) return;
          // TODO: Partial value updates?! WHAT?
          onSelect({ metric });
        }}
      />
      {!!showCalculations && (
        <CalculationList
          value={value?.calculation}
          onClick={calculation => onSelect({ calculation })}
        />
      )}
      {!!showCharts && (
        <ChartList
          list={keys(CHARTS).map(chart_key => CHARTS[chart_key])}
          value={value?.type}
          disableNonComposeCharts={disableNonComposeCharts}
          onClick={type => onSelect({ type })}
        />
      )}
    </div>
  );
}

export default function MetricSelector(props) {
  const { components = {}, className, dropdown = true, disabled, ...rest } = props;
  if (!dropdown) {
    return <MetricDropdownMenu disabled={disabled} {...rest} />;
  }

  const { button } = components;

  return (
    <Dropdown
      className={cx(className, disabled && 'opacity-50 cursor-not-allowed')}
      disabled={disabled}
      components={{
        button,
        body: <MetricDropdownMenu {...rest} />,
      }}
    />
  );
}
