import { z } from 'zod';

import DATA_TYPES from 'modals/skill-pipeline-builder/config/node-data-types';

export default {
  key: 'BoundingBoxCropElement',
  label: 'Dynamic Crop',
  icon: 'Crop',
  description:
    'Creates a new image by cropping an input image to the region defined by the given bounding box with the highest confidence score.',
  type: 'graphic_elements',
  inputs: [
    {
      label: 'Source Image',
      key: 'full_res_image',
      type: DATA_TYPES['Image'],
      required: true,
    },
    {
      label: 'Bounding Boxes',
      key: 'full_res_bounding_boxes',
      type: DATA_TYPES['BoundingBoxes'],
      required: true,
    },
  ],
  outputs: [
    {
      key: 'image',
      label: 'Image',
      type: DATA_TYPES['Image'],
    },
  ],
  defaultConfig: {
    label: '',
    file_prefix: '',
    left_margin: 0.0,
    right_margin: 0.0,
    top_margin: 0.0,
    bottom_margin: 0.0,
  },
  schema: z.object({
    config: z.object({
      label: z.string().min(1),
      file_prefix: z.string().min(1),
      left_margin: z.number().min(0),
      right_margin: z.number().min(0),
      top_margin: z.number().min(0),
      bottom_margin: z.number().min(0),
    }),
    inputs: z.object({
      full_res_image: z.string().min(1),
      full_res_bounding_boxes: z.string().min(1),
    }),
  }),
};
