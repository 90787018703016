import { range } from 'lodash';
import moment from 'moment';

import { Card, Group } from '@optra/kit';

import FeatureToggle from 'components/feature-toggle';

import { useChartCtx } from '../context';

export default function ControlDaySelection(props) {
  const {
    heading = 'DAYS OF WEEK',
    description = 'Select the days of the week to include in your query',
    collapsible = true,
    scrollable = false,
    defaultCollapsed = false,
    ...rest
  } = props;

  const {
    helpers: { getChartConfig },
    actions: { toggleDisabledDays },
  } = useChartCtx();
  const { disabledDays: _disabledDays } = getChartConfig();
  const disabledDays = _disabledDays || [];

  const values = range(1, 8).map(value => ({
    day: moment().day(value).format('dddd'),
    value,
  }));

  const count = 7 - disabledDays.length;

  return (
    <Group
      heading={heading}
      description={description}
      count={count}
      collapsible={collapsible}
      scrollable={scrollable}
      defaultCollapsed={defaultCollapsed}
      {...rest}
    >
      <Card variant="dark" className="pb-3">
        {values.map(({ day, value }) => {
          const isActive = !disabledDays.includes(value);
          return (
            <FeatureToggle
              key={day}
              variant="plain"
              title={day}
              checked={!!isActive}
              onClick={() => toggleDisabledDays(value)}
              uppercase={false}
              bold={false}
            />
          );
        })}
      </Card>
    </Group>
  );
}
