import { isEmpty, last } from 'lodash';
import { useState, useEffect, useRef, useMemo } from 'react';

import { PrimaryCTAButton, Text, PageWrapper } from '@optra/kit';

import AdminUsageList from 'components/admin-usage-list';
import Label from 'components/label';
import OrganizationSelect from 'components/organization-select';
import PaginationButtons from 'components/pagination-buttons';
import PinPageButton from 'components/pin-page-button';
import { api, q } from 'config/api';
import { useRouteQueryState } from 'hooks';
import pluralize from 'lib/pluralize';
import { useOrganizations, useProvisionedDevicesUsage } from 'queries';

export default function AdminUsagePage() {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState('all');
  const [cursorBefore, setCursorBefore] = useRouteQueryState('before', null);
  const [cursorAfter, setCursorAfter] = useRouteQueryState('after', null);

  const Organizations = useOrganizations({ all: true });
  const organizations = Organizations.data;

  const initialized = useRef(false);
  useEffect(() => {
    if (!selectedOrganizationId && !initialized.current) {
      const initialOrganizationId = organizations[0]?.id;
      setSelectedOrganizationId(initialOrganizationId);
      if (initialOrganizationId) initialized.current = true;
    }
  }, [selectedOrganizationId, setSelectedOrganizationId, organizations, initialized]);

  const devices = useProvisionedDevicesUsage(
    {
      rootList: {
        sort: { by: 'createdAt', direction: 'asc' },
        filter: {
          ownerOrganizationId: selectedOrganizationId === 'all' ? null : selectedOrganizationId,
        },
        cursor: {
          before: cursorBefore,
          after: cursorAfter,
        },
      },
      usagesList: {
        filter: {
          ownerId: selectedOrganizationId === 'all' ? null : selectedOrganizationId,
        },
      },
    },
    { enabled: !!selectedOrganizationId },
  );

  const exportAllDevices = q.useMutation({
    mutationFn: form =>
      api(
        `mutation ($form: exportOrganizationsDevicesForm!) {
          csv: exportOrganizationsDevices(form: $form) {
            url
          }
        }`,
        { form },
      ),
  });

  const handleSelectOrganizationId = nextOrganizationId => {
    setCursorAfter(null);
    setCursorBefore(null);
    setSelectedOrganizationId(nextOrganizationId);
  };

  const handlePrevPage = () => {
    setCursorAfter(null);
    setCursorBefore(devices?.data?.devices?.cursor?.before);
  };

  const handleNextPage = () => {
    setCursorBefore(null);
    setCursorAfter(devices?.data?.devices?.cursor?.after);
  };

  const usages = useMemo(() => {
    const _devices = devices?.data?.devices?.data || [];
    const systemOrg = { name: '$$SYSTEM' };

    return _devices.reduce((prev, curr) => {
      // If there are no usages, create a device with no messages
      if (curr.usages.count < 1) {
        prev.push({
          provisionedDevice: curr,
          device: curr.device,
          ownerOrganization: curr.organization || systemOrg,
          ownerUser: curr.claimantId || systemOrg,
          messagesTransmitted: [],
        });

        return prev;
      }

      // Create a 'device' for each usage
      prev.push(
        // ...curr.usages.data,
        ...curr.usages.data.map(usage => ({
          provisionedDevice: curr,
          device: curr.device,
          ownerOrganization: usage.ownerOrganization || systemOrg,
          ownerUser: usage.ownerUser || systemOrg,
          messagesTransmitted: usage.messagesTransmitted,
        })),
      );
      return prev;
    }, []);
  }, [devices?.data?.devices?.data]);

  return (
    <PageWrapper
      icon="Activity"
      title="Usage | Admin"
      heading="Usage"
      inline={true}
      loading={devices?.isLoading}
      error={devices?.error}
      components={{
        actions: (
          <>
            <PinPageButton />
            <PrimaryCTAButton
              onClick={async () => {
                if (exportAllDevices.isPending) return;
                const r = await exportAllDevices.mutateAsync({
                  organizationId: selectedOrganizationId,
                });
                const csv = r?.data?.csv || r?.csv;
                if (r.error || !csv) return;
                const link = document.createElement('a');
                link.download = last(csv.url.split('/'));
                link.href = csv.url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }}
              loading={exportAllDevices.isPending}
              icon="DownloadSimple"
              text="Export"
            />
          </>
        ),
        controls: (
          <div className="flex items-center justify-end gap-2">
            <Label>Organization</Label>
            <OrganizationSelect
              onChange={handleSelectOrganizationId}
              value={selectedOrganizationId}
              filter={{ all: true }}
            />
          </div>
        ),
      }}
    >
      <div className="mb-3">
        <Text>
          {`${devices?.data?.devices?.count || 0} ${pluralize(
            'Device',
            devices?.data?.devices?.count,
          )}`}
        </Text>
      </div>
      <AdminUsageList fetching={devices?.isLoading} devices={usages} />

      {!isEmpty(usages) && (
        <PaginationButtons
          onPrevPage={handlePrevPage}
          hasPrev={devices?.data?.devices?.cursor?.before}
          onNextPage={handleNextPage}
          hasNext={devices?.data?.devices?.cursor?.after}
          loading={devices?.isRefetching}
        />
      )}
    </PageWrapper>
  );
}
