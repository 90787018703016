import cx from 'classnames';
import _isFinite from 'lodash/isFinite';

import { Icon, Text } from '@optra/kit';

import Input from 'components/input';

// TODO: Rename and move to kit
export default function StepperInput({
  placeholder,
  value,
  name,
  disabled = false,
  min = 0,
  max = 1000,
  step = 1,
  label,
  onChange = () => false,
  className,
  showUnitLabel,
  unitLabel = '%',
  ...rest
}) {
  function handleIncrement() {
    if (disabled) return;
    const newValue = parseInt(value, 10) + step;
    if (!_isFinite(newValue)) return;
    onChange(newValue > max ? max : newValue);
  }

  function handleDecrement() {
    if (disabled) return;
    const newValue = parseInt(value, 10) - step;
    if (!_isFinite(newValue)) return;
    onChange(newValue < min ? min : newValue);
  }

  function handleChangeValue(event) {
    if (disabled) return;
    const newValue = parseInt(event.target.value, 10);
    if (!_isFinite(newValue)) return;
    onChange(newValue > max || newValue < min ? value : newValue);
  }

  function handleKeydown(event) {
    if (disabled) return;
    if (event.which === 38) {
      event.preventDefault();
      handleIncrement();
    }

    if (event.which === 40) {
      event.preventDefault();
      handleDecrement();
    }
  }

  return (
    <div className={cx(['relative', 'select-none', className])} {...rest}>
      <Input
        type="text"
        name={name}
        placeholder={placeholder}
        value={label ? [value, label].join(' ') : value}
        disabled={disabled}
        readOnly={disabled}
        onKeyDown={handleKeydown}
        onChange={handleChangeValue}
      />
      <Icon
        onClick={handleIncrement}
        className="w-4 h-4 absolute top-1 right-4"
        name="CaretUp"
        weight="line"
        size="xs"
      />
      <Icon
        onClick={handleDecrement}
        className="w-4 h-4 absolute bottom-1 right-4"
        name="CaretDown"
        weight="line"
        size="xs"
      />
      {showUnitLabel && (
        <Text
          size="xs"
          color="muted"
          className="absolute inset-y-0 right-9 w-6 flex justify-center items-center pointer-events-none"
        >
          {unitLabel}
        </Text>
      )}
    </div>
  );
}
