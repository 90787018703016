import flatMap from 'lodash/flatMap';

import IntersectionLoadMore from 'components/intersection-load-more';
import WorkflowActionListItem from 'components/workflow-action-list-item';
import WorkflowCard from 'components/workflow-card';
import { useHasRoles } from 'hooks';
import { useWorkflowsActions } from 'queries';

export default function WorkflowActions({
  workflowId,
  shouldFetch,
  expanded = true,
  disabled = false,
}) {
  const [canEdit] = useHasRoles(['admin', 'workflowEditor', 'workflowDeployer']);

  const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } = useWorkflowsActions(
    workflowId,
    {
      list: {
        limit: 12,
      },
    },
    {
      enabled: !!workflowId && shouldFetch,
    },
  );
  const actions = flatMap(data?.pages, page => page?.workflow?.list?.data);

  return (
    <WorkflowCard
      icon="Lightning"
      heading="Actions"
      plusUrl={`/workflows/${workflowId}/workflow-actions/add`}
      count={data?.pages?.[0]?.workflow?.list?.count}
      loading={isLoading}
      disabled={!canEdit || disabled}
      expanded={expanded}
    >
      {actions
        ?.filter(node => !!node)
        ?.map?.(workflowAction => (
          <WorkflowActionListItem
            key={workflowAction.id}
            id={workflowAction.id}
            workflowId={workflowId}
            name={workflowAction.action.name}
            type={workflowAction.action.id}
            dirty={!workflowAction.synchronized && !workflowAction.isSynchronizing}
            deleted={workflowAction.deleted}
            disabled={disabled}
            actionDisabled={workflowAction.disabled}
          />
        ))}

      <IntersectionLoadMore
        onVisible={fetchNextPage}
        disabled={isLoading || isFetchingNextPage || !hasNextPage}
      />
    </WorkflowCard>
  );
}
