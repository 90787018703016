import { filter, isBoolean, isFunction, isObject, isString, keys, uniq } from 'lodash';

import alias from 'modals/chart-details/charts-query-engine/alias';
import {
  SPACE,
  NEW_LINE,
  INDENT,
  NEW_LINE_INDENT,
  NEW_LINE_DOUBLE_INDENT,
  COMMA,
} from 'modals/chart-details/charts-query-engine/characters';
import dateTrunc from 'modals/chart-details/charts-query-engine/date-trunc';
import round from 'modals/chart-details/charts-query-engine/round';

import {
  TIMEFRAMES,
  TIMEFRAME_BINS,
  SMART_TIMEFRAMES,
  CONSTANTS,
  DIMENSIONS,
  TIME_GROUP_DIMENSIONS,
  INTERPOLATION_STYLES,
} from './data';
import _helpers from './helpers';

export default function useChartSql(params = {}) {
  const { chartConfig = {} } = params;

  const utilities = {
    getClauseValues: clause => {
      if (!clause) return [];
      return (Array.isArray(clause.values) ? clause.values : clause) || [];
    },

    //////////////////////////////////////////////
    // returns: "{{value}}, {{value}}, {{value}}, ..."
    // e.g. "time, available_spaces, device_id"
    //////////////////////////////////////////////
    formatClauseValues: (_list, options = {}) => {
      const list = _helpers.cleanList(_list);
      if (!list.length) return;

      const {
        join: _join = COMMA,
        newLineBeforeJoin,
        newLineFirst,
        newLineEnd,
        newLines = true,
        clauseIndent,
      } = options;

      const indent = clauseIndent ? NEW_LINE_DOUBLE_INDENT : NEW_LINE_INDENT;

      const initialCharacter = newLines ? (list.length > 1 || newLineFirst ? indent : SPACE) : '';
      const join = newLines
        ? newLineBeforeJoin
          ? `${indent}${_join}`
          : `${_join}${indent}`
        : _join;

      return `${initialCharacter}${list.join(join)}` + (newLineEnd ? NEW_LINE_INDENT : '');
    },

    //////////////////////////////////////////////
    // returns: "{{name}} {{value}}, {{value}}, {{value}}, ..."
    // e.g. "SELECT time, available_spaces, device_id"
    //////////////////////////////////////////////
    formatClause: (name, _list, options = {}) => {
      const list = _helpers.cleanList(_list);
      if (!list.length) return;

      const { indent: clauseIndent } = options;

      return `${clauseIndent ? INDENT : ''}${name}${utilities.formatClauseValues(list, {
        ...options,
        clauseIndent,
      })}`;
    },
  };

  const builders = {
    createAlias: alias,

    //////////////////////////////////////////////
    // returns: "TIMESTAMP '{{time}}'"
    // e.g. "TIMESTAMP '2022-12-12 22:39:00.000'"
    //////////////////////////////////////////////
    createTimestamp: ({ time, format = CONSTANTS.TIMESTREAM_TIMESTAMP_FORMAT }) => {
      const m = _helpers.createMoment(time);
      if (!m) return;
      return `TIMESTAMP${SPACE}'${m.format(format)}'`;
    },

    //////////////////////////////////////////////
    // returns: "date_trunc('{{unit_name}}', {{key}})"
    // e.g. "date_trunc('week', time)"
    //////////////////////////////////////////////
    timeTrunc: (params = {}) => {
      const { key = DIMENSIONS.time.type, interval_literal = chartConfig?.timeframeBin } = params;
      const timeframeBin = TIMEFRAME_BINS[interval_literal];
      if (!timeframeBin) return key;
      const { trunc_unit, unit } = timeframeBin;
      return dateTrunc(trunc_unit || unit, key);
    },

    //////////////////////////////////////////////
    // returns: "extract({{exract}} FROM {{key}})"
    // e.g. "extract(DAY_OF_WEEK FROM time)"
    //////////////////////////////////////////////
    timeExtract: (params = {}) => {
      const { key = DIMENSIONS.time.type, extract } = params;
      if (!extract) return;
      return `extract(${extract}${SPACE}FROM${SPACE}${key})`;
    },

    //////////////////////////////////////////////
    // returns: "bin({{key}}, {{interval_literal}})"
    // e.g. "bin(time, 7d)"
    //////////////////////////////////////////////
    createTimeBin: (params = {}) => {
      const { key = DIMENSIONS.time.type, interval_literal = chartConfig?.timeframeBin } = params;
      const timeframeBin = TIMEFRAME_BINS[interval_literal];
      if (!timeframeBin) return key;

      const { type } = timeframeBin;

      return `bin(${key}${COMMA}${type})`;
    },

    //////////////////////////////////////////////
    // returns: "{{value}} ASC/DESC"
    // e.g. "time ASC"
    //////////////////////////////////////////////
    createOrderByValue: (params = {}) => {
      const { value, direction = 'ASC' } = params;
      if (!value) return;
      return `${value}${SPACE}${direction}`;
    },

    //////////////////////////////////////////////
    // returns: "COALESCE({{value[0]}}, {{value[1]}})"
    // e.g. "COALESCE(tags.test1, tags.test2)"
    //////////////////////////////////////////////
    createCoalesce: (list, formatOptions = {}) => {
      if (!Array.isArray(list)) return;
      return `COALESCE(${utilities.formatClauseValues(list, {
        newLines: false,
        ...formatOptions,
      })})`;
    },

    //////////////////////////////////////////////
    // returns: "{{value}} IS NOT NULL"
    // returns: "{{value[0]}} IS NOT NULL AND {{value[1]}} IS NOT NULL"
    // returns: "COALESCE({{value[0]}}, {{value[1]}}) IS NOT NULL"
    // e.g. "available IS NOT NULL"
    // e.g. "available IS NOT NULL AND occupied IS NOT NULL"
    // e.g. "COALESCE(available, occupied) IS NOT NULL"
    //////////////////////////////////////////////
    createNoNullValueCondition: (params = {}) => {
      const { value, coalesce } = params;
      if (!value) return;

      const isNotNullString = `${SPACE}IS${SPACE}NOT${SPACE}NULL`;
      const formatOptions = { newLines: false };

      if (Array.isArray(value)) {
        if (coalesce) {
          return (
            (value?.length > 1 ? builders.createCoalesce(value, formatOptions) : value[0]) +
            isNotNullString
          );
        }
        return (
          '(' +
          utilities.formatClauseValues(
            value.map(v => `${v}${isNotNullString}`),
            { ...formatOptions, join: `${SPACE}AND${SPACE}` },
          ) +
          ')'
        );
      }

      return `${value}${isNotNullString}`;
    },

    //////////////////////////////////////////////
    // returns: "{{key}} IN ({{value}}, {{value}}, {{value}}, ...)"
    // e.g. "workflow_id IN ('343065868307006031', '343065868307006032', '343065868307006033')"
    //////////////////////////////////////////////
    createArrayCondition: ({ key, value }, options = {}) => {
      if (!key || !Array.isArray(value)) return;

      const list = _helpers.cleanList(value);
      if (!list.length) return;

      const { inverseCondition, isString = true } = options || {};

      const idList = _helpers
        .cleanList(
          list.map(id => {
            if (isString) return `'${id}'`;
            return id;
          }),
        )
        .join(COMMA);

      const condition = inverseCondition ? `NOT${SPACE}IN` : 'IN';
      return `${key}${SPACE}${condition}${SPACE}(${idList})`;
    },

    //////////////////////////////////////////////
    // returns: builders.formatClause
    // e.g. "ORDER_BY time ASC, available_spaces ASC, device_id ASC"
    //////////////////////////////////////////////
    createClause: (name, clause, _options = {}) => {
      if (!name || !clause) return;

      let values;
      let options = _options;

      if (isString(clause)) {
        values = [clause];
      } else if (Array.isArray(clause)) {
        values = clause;
      } else if (isObject(clause)) {
        values = clause.values;
        options = { ...options, ...clause.options };
      }

      if (isFunction(options?.iteratee)) {
        values = values.map(options.iteratee);
      }

      return utilities.formatClause(name, uniq(values), options);
    },

    //////////////////////////////////////////////
    // returns: "time >= TIMESTAMP '{{YYYY-MM-DD H:mm:ss.sss}}'
    //           AND time <= TIMESTAMP '{{YYYY-MM-DD H:mm:ss.sss}}'
    // e.g. "time >= TIMESTAMP '2022-12-12 0:00:00.000'
    //      AND time <= TIMESTAMP '2022-12-12 0:00:00.000'
    //////////////////////////////////////////////

    createDayRangeCondition: (params = {}) => {
      const { key = DIMENSIONS.time.type, start: _start, end: _end } = params;

      const start = _helpers.createMoment(_start);
      const end = _helpers.createMoment(_end);

      if (!start || !end) return;

      const startDate = builders.createTimestamp({
        time: _helpers.convertDateToEpoch(start.startOf('day')),
      });
      const endDate = builders.createTimestamp({
        time: _helpers.convertDateToEpoch(end.add(1, 'day').startOf('day')),
      });

      const timeframeConditions = [
        `${key}${SPACE}>=${SPACE}${startDate}`,
        `${key}${SPACE}<${SPACE}${endDate}`,
      ];

      return '(' + timeframeConditions.join(`${NEW_LINE_INDENT}AND${SPACE}`) + ')';
    },

    //////////////////////////////////////////////
    // returns: "time BETWEEN ago({{interval_literal}}) AND now()"
    // e.g. "time BETWEEN ago(30d) AND now()"
    //////////////////////////////////////////////
    createTimeframeAgoCondition: (params = {}) => {
      const { key = DIMENSIONS.time.type, interval_literal } = params;
      const intervalLiteral = TIMEFRAMES[interval_literal];
      if (!intervalLiteral) return;
      return `${key}${SPACE}BETWEEN${SPACE}date_trunc('${intervalLiteral.unit}', ago(${interval_literal}))${SPACE}AND${SPACE}now()`;
    },

    //////////////////////////////////////////////
    // returns: "time >= TIMESTAMP '{{YYYY-MM-DD H:mm:ss.sss}}'
    //           AND time < date_add('{{unit_name}}', {{unit_value}}, TIMESTAMP '{{YYYY-MM-DD H:mm:ss.sss}}')"
    // e.g. "time >= TIMESTAMP '2022-12-12 0:00:00.000'
    //      AND time < date_add('day', 7, TIMESTAMP '2022-12-12 0:00:00.000')"
    //////////////////////////////////////////////
    createTimeframeIntervalFromCondition: (params = {}) => {
      const {
        key = DIMENSIONS.time.type,
        time,
        interval_literal = chartConfig?.timeframeBin,
      } = params;
      const timestamp = builders.createTimestamp({ time });

      if (!timestamp) return;

      const timeframeBin = TIMEFRAME_BINS[interval_literal];

      if (!timeframeBin) return;

      const start = timestamp;
      const end = `date_add('${timeframeBin?.unit}', ${timeframeBin?.value}, ${timestamp})`;

      const timeframeConditions = [
        `${key}${SPACE}>=${SPACE}${start}`,
        `${key}${SPACE}<${SPACE}${end}`,
      ];

      return timeframeConditions.join(`${NEW_LINE_INDENT}AND${SPACE}`);
    },

    //////////////////////////////////////////////
    // returns: various
    //////////////////////////////////////////////
    createTimeframeCondition: (params = {}) => {
      const { key = DIMENSIONS.time.type, start, end, db, table } = params;
      if (!start) return;

      if (SMART_TIMEFRAMES[start]) return SMART_TIMEFRAMES[start]?.resolver?.({ key, db, table });

      if (TIMEFRAMES[start])
        return builders.createTimeframeAgoCondition({ key, interval_literal: start, db, table });

      return builders.createDayRangeCondition({ key, start, end, db, table });
    },

    //////////////////////////////////////////////
    // returns: "{{key}} BETWEEN {{value - 0.001}} AND {{value + 0.001}}"
    // e.g. "available_spaces BETWEEN .999 AND 1.001"
    //////////////////////////////////////////////
    createFloatCondition: (key, _value) => {
      if (!key) return;

      const value = parseFloat(_value);

      if (Number.isNaN(value)) return;

      const offset = 0.001;
      // round helper precision defaults to 3
      const lowerLimit = _helpers.round(value - offset);
      const upperLimit = _helpers.round(value + offset);

      return `${key}${SPACE}BETWEEN${SPACE}${lowerLimit}${SPACE}AND${SPACE}${upperLimit}`;
    },

    //////////////////////////////////////////////
    // returns: "{{key}} = '1/0'"
    // e.g. "store_open = '0'"
    //////////////////////////////////////////////
    createBooleanCondition: ({ key, value }) => {
      if (!key || !isBoolean(value)) return;
      return `${key}${SPACE}=${SPACE}${value ? 'True' : 'False'}`;
    },

    //////////////////////////////////////////////
    // returns: builders.createTimeframeCondition
    // returns: builders.createArrayCondition
    // returns: builders.createBooleanCondition
    // returns: builders.createFloatCondition
    // e.g. "workflow_id IN ('343065868307006031', '343065868307006032', '343065868307006033')"
    //////////////////////////////////////////////
    createWhereConditions: (data, options) => {
      if (!data || !isObject(data)) return [];
      const { timeKey } = options || {};
      const conditionStringArray = _helpers.cleanList([
        ...keys(data).map(key => {
          const value = data[key];
          if (!value) return undefined;
          if (_helpers.isTimeGroupDimension(key)) {
            return `${builders.timeExtract({
              key: timeKey,
              extract: TIME_GROUP_DIMENSIONS[key].extract,
            })}${SPACE}=${SPACE}${value}`;
          }
          if (_helpers.isTimeDimension(key)) {
            return builders.createTimeframeIntervalFromCondition({ key: timeKey, time: value });
          }
          if (_helpers.isDimension(key)) {
            return builders.createArrayCondition({
              key,
              value: Array.isArray(value) ? value : [value],
            });
          }
          if (Array.isArray(value)) {
            return builders.createArrayCondition({ key, value });
          }
          if (isBoolean(value)) {
            return builders.createBooleanCondition({ key, value });
          }
          return builders.createFloatCondition(key, value);
        }),
      ]);

      return conditionStringArray;
    },
  };

  const methods = {
    //////////////////////////////////////////////
    // returns: SELECT
    //            {{value}}, {{value}}, {{value}}, ...
    //          FROM {{table}}
    //          WHERE
    //            {{condition}}, {{condition}}, {{condition}}, ...
    //          GROUP BY
    //            {{value}}, {{value}}, {{value}}, ...
    //          ORDER BY
    //            {{value}} ASC/DESC, {{value}} ASC/DESC, {{value}} ASC/DESC, ...
    // e.g. SELECT
    //        date_trunc('week', time) AS time,
    //        device_id,
    //        round(SUM(available), 3) AS available
    //      FROM "marz"."test"
    //      WHERE
    //        available IS NOT NULL
    //        AND time BETWEEN ago(180d) AND now()
    //        AND workflow_id IN ('343065868307006032')
    //      GROUP BY
    //        date_trunc('week', time),
    //        device_id
    //      ORDER BY
    //        time ASC,
    //        device_id ASC
    //////////////////////////////////////////////
    generateSelectStatement: (statementObj, options = {}) => {
      if (!statementObj) return;

      const { SELECT, FROM, WHERE, GROUP_BY, ORDER_BY } = statementObj;

      if (!SELECT || !FROM) return;

      const select = _helpers
        .cleanList([
          builders.createClause('SELECT', SELECT, options),
          builders.createClause('FROM', FROM, options),
          builders.createClause('WHERE', WHERE, {
            join: `AND${SPACE}`,
            newLineBeforeJoin: true,
            ...options,
          }),
          builders.createClause('GROUP BY', GROUP_BY, options),
          builders.createClause('ORDER BY', ORDER_BY, {
            iteratee: ORDER_BY?.options?.direction
              ? column =>
                  builders.createOrderByValue({
                    value: column,
                    direction: ORDER_BY.options.direction,
                  })
              : null,
            ...options,
          }),
        ])
        .join(NEW_LINE);

      return select;
    },

    //////////////////////////////////////////////
    // e.g.
    //
    // WITH data AS (
    //   SELECT
    //     date_trunc('second', time) AS time,
    //     device_id,
    //     round(SUM(available), 3) AS available
    //   FROM "marz"."test"
    //   WHERE
    //     time BETWEEN ago(90d) AND now()
    //     AND available IS NOT NULL
    //     AND workflow_id IN ('343065868307006032')
    //   GROUP BY
    //     date_trunc('second', time),
    //     device_id
    //   ORDER BY
    //     time ASC,
    //     device_id ASC
    // ), interpolated_data AS (
    //   SELECT
    //     device_id,
    //     interpolate_fill(create_time_series(time, available), sequence(min(time), max(time), 1s), 0) AS interpolated_available
    //   FROM data
    //   GROUP BY device_id
    // )
    // SELECT
    //   time,
    //   device_id,
    //   round(value, 3) AS available
    // FROM interpolated_data
    // CROSS JOIN unnest(interpolated_available)
    // ORDER BY
    //   time ASC,
    //   device_id ASC
    //////////////////////////////////////////////
    generateInterpolationQuery: ({
      chartQueryData,
      metric,
      columns: _columns,
      timeKey = DIMENSIONS.time.type,
      interpolationStyle: _interpolationStyle,
      interpolationFill: _interpolationFill,
      discardZeros,
      interval_literal = chartConfig?.timeframeBin,
    }) => {
      const selectStatement = methods.generateSelectStatement(chartQueryData, { indent: true });

      if (!selectStatement) return;

      const { ORDER_BY } = chartQueryData;

      const seriesName = 'data';
      const interpolatedSeriesName = `interpolated_${seriesName}`;

      const interpolatedMetric = `interpolated_${metric}`;

      const interpolationTimeSeries = `create_time_series(${utilities.formatClauseValues(
        [timeKey, metric],
        { newLines: false },
      )})`;

      // Interpolation cannot happen outside the range of available timestamps.
      // We can only interpolate between captured timestamps.
      // Setting the end range to now() will break.
      // NOTE: This applies for EACH split out legend group
      const interpolationSequence = `sequence(${utilities.formatClauseValues(
        [`min(${timeKey})`, `max(${timeKey})`, interval_literal],
        { newLines: false },
      )})`;

      const interpolationStyle = INTERPOLATION_STYLES[_interpolationStyle]
        ? INTERPOLATION_STYLES[_interpolationStyle]
        : INTERPOLATION_STYLES.interpolate_fill;

      let interpolationFill;

      if (interpolationStyle.type === INTERPOLATION_STYLES.interpolate_fill.type) {
        interpolationFill = !!_interpolationFill ? `${parseFloat(_interpolationFill)}` : '0';
      }

      const interpolationFunction = `${interpolationStyle.funcName}(${utilities.formatClauseValues(
        [interpolationTimeSeries, interpolationSequence, interpolationFill],
        { newLines: false },
      )})`;

      const columns = _columns || utilities.getClauseValues(ORDER_BY);
      const additionalColumns = filter(columns, column => column !== timeKey);

      const interpolatingSelect = methods.generateSelectStatement(
        {
          SELECT: [
            ...additionalColumns,
            alias({ text: interpolationFunction, alias: interpolatedMetric }),
          ],
          FROM: seriesName,
          GROUP_BY: additionalColumns,
        },
        { indent: true },
      );

      const unnestingSelect =
        NEW_LINE +
        methods.generateSelectStatement({
          SELECT: [
            ...columns,
            alias({
              text: round('value'),
              alias: metric,
            }),
          ],
          FROM: interpolatedSeriesName,
        });

      const joinStatement = NEW_LINE + `CROSS${SPACE}JOIN${SPACE}unnest(${interpolatedMetric})`;

      const selectAliasConfig = {
        includeParens: true,
        newLine: true,
      };

      const finalWhere = discardZeros
        ? NEW_LINE +
          builders.createClause('WHERE', [
            `(value${SPACE}>${SPACE}0${SPACE}OR${SPACE}value${SPACE}<${SPACE}0)`,
          ])
        : undefined;

      const finalOrderBy =
        NEW_LINE +
        builders.createClause('ORDER BY', columns, {
          iteratee: column =>
            builders.createOrderByValue({
              value: column,
              direction: ORDER_BY?.options?.direction,
            }),
        });

      return [
        'WITH',
        SPACE,
        alias({ text: seriesName, alias: selectStatement }, selectAliasConfig),
        COMMA,
        alias({ text: interpolatedSeriesName, alias: interpolatingSelect }, selectAliasConfig),
        unnestingSelect,
        joinStatement,
        finalWhere,
        finalOrderBy,
      ].join('');
    },
  };

  return {
    ...builders,
    ...methods,
    ...utilities,
  };
}
