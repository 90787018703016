import { isEmpty, last } from 'lodash';

import { PrimaryCTAButton, Button, PageWrapper, SearchField } from '@optra/kit';

import AdminProvisioningList from 'components/admin-provisioning-list';
import PaginationButtons from 'components/pagination-buttons';
import PinPageButton from 'components/pin-page-button';
import { api, q } from 'config/api';
import modelNames from 'config/model-names';
import { useRouteQueryState } from 'hooks';
import { useProvisionedDevices } from 'queries';

function ProvisionDeviceButton({ model }) {
  const qc = q.useQueryClient();
  const createProvisionedDevice = q.useMutation({
    mutationFn: form =>
      api(
        `mutation createProvisionedDevice($form: createProvisionedDeviceForm!) {
          device: createProvisionedDevice(form: $form) {
            id
          }
        }`,
        { form },
      ),
    onSuccess() {
      qc.invalidateQueries({ queryKey: ['provisionedDevices'] });
    },
  });

  const label = modelNames[model];
  const provision = () => {
    if (createProvisionedDevice.isPending) return;

    if (window.confirm(`Provision a new ${label} device?`)) {
      createProvisionedDevice.mutate({
        noCert: true,
        model,
      });
    }
  };

  return (
    <Button
      variant="tertiary"
      size="xs"
      onClick={provision}
      loading={createProvisionedDevice.isPending}
      icon="Plus"
    >
      {label}
    </Button>
  );
}

function ExportProvisionedDevicesButton() {
  const exportDevices = q.useMutation({
    mutationFn: () =>
      api(
        `mutation exportProvisionedDevices {
          csv: exportProvisionedDevices {
            url
          }
        }`,
      ),
    onSuccess({ csv }) {
      const link = document.createElement('a');
      link.download = last(csv.url.split('/'));
      link.href = csv.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  });

  return (
    <PrimaryCTAButton
      onClick={async () => {
        if (exportDevices.isPending) return;
        exportDevices.mutate();
      }}
      loading={exportDevices.isPending}
      icon="DownloadSimple"
      text="Export"
    />
  );
}

export default function AdminProvisioningPage() {
  const [cursorBefore, setCursorBefore] = useRouteQueryState('before', null);
  const [cursorAfter, setCursorAfter] = useRouteQueryState('after', null);
  const [filter, setFilter] = useRouteQueryState('filter', null);

  const { data, isLoading, isRefetching, isFetchedAfterMount, error } = useProvisionedDevices({
    list: { filter, cursor: { before: cursorBefore, after: cursorAfter } },
  });

  const handlePrevPage = () => {
    setCursorAfter(null);
    setCursorBefore(data?.list?.cursor?.before);
  };

  const handleNextPage = () => {
    setCursorBefore(null);
    setCursorAfter(data?.list?.cursor?.after);
  };

  return (
    <PageWrapper
      icon="GitFork"
      title="Provisioning | Admin"
      heading="Provisioning"
      inline={true}
      loading={isLoading}
      error={error}
      components={{
        actions: (
          <>
            <PinPageButton />
            <ExportProvisionedDevicesButton />
          </>
        ),
        controls: (
          <div className="flex items-center justify-between space-x-2">
            <SearchField
              searching={isRefetching && !isFetchedAfterMount}
              value={filter?.$search || ''}
              onChange={$search => {
                setFilter(prev => ({ ...prev, $search }));
              }}
              onClear={() => {
                setFilter(prev => ({ ...prev, $search: '' }));
              }}
              placeholder="Search Devices…"
              className="w-full max-w-sm"
            />

            <ProvisionDeviceButton model="cx1000" />
            <ProvisionDeviceButton model="compute550" />
            <ProvisionDeviceButton model="vz1000" />
            <ProvisionDeviceButton model="vz5000" />
          </div>
        ),
      }}
    >
      <AdminProvisioningList devices={data?.list?.data} />
      {!isEmpty(data?.list?.data) && (
        <PaginationButtons
          onPrevPage={handlePrevPage}
          hasPrev={data?.list?.cursor?.before}
          onNextPage={handleNextPage}
          hasNext={data?.list?.cursor?.after}
          loading={isRefetching}
        />
      )}
    </PageWrapper>
  );
}
