import { Draggable } from 'react-beautiful-dnd';

import { Group } from '@optra/kit';

import { useChartCtx } from '../context';

import { AdditemButton, DraggableItem, DroppableZone } from './fragments';
import MetricSelector from './metric-selector';

///////////////////////
// METRICS
///////////////////////

export default function ControlMetrics(props) {
  const {
    heading = 'Metrics',
    description = 'Select the primary data to chart',
    collapsible = true,
    scrollable = false,
    defaultCollapsed = false,
    ...rest
  } = props;
  const {
    actions: { addMetric, removeMetric, updateMetric },
    helpers: { getChartConfig },
    CALCULATIONS,
    CHARTS,
  } = useChartCtx();

  const { metrics = [] } = getChartConfig();

  const hasMetrics = !!metrics?.length;

  const count = metrics?.length;

  return (
    <Group
      heading={heading}
      description={description}
      count={count}
      collapsible={collapsible}
      scrollable={scrollable}
      defaultCollapsed={defaultCollapsed}
      {...rest}
    >
      <DroppableZone droppableId="metrics">
        {({ placeholder, ...droppableProps }) => (
          <div {...droppableProps}>
            {hasMetrics &&
              metrics.map((metricObject, i) => {
                const { metric, calculation, type } = metricObject;
                return (
                  <Draggable key={metric} draggableId={metric} index={i}>
                    {(provided, snapshot) => {
                      const disableNonComposeCharts =
                        metrics.length > 1 ||
                        (!CHARTS[metrics[0]?.type].compose && metrics.length !== 1);
                      return (
                        <MetricSelector
                          value={metricObject}
                          onSelect={data => updateMetric(metricObject, data)}
                          disableNonComposeCharts={disableNonComposeCharts}
                          components={{
                            button: (
                              <DraggableItem
                                variable={metric}
                                details={[CALCULATIONS[calculation]?.name, CHARTS[type]?.name]}
                                draggableProps={{ provided, snapshot }}
                                onRemove={() => removeMetric(metric)}
                              />
                            ),
                          }}
                        />
                      );
                    }}
                  </Draggable>
                );
              })}
            {placeholder}
            <MetricSelector
              onSelect={({ metric }) => addMetric({ metric })}
              components={{
                button: <AdditemButton {...rest} />,
              }}
              showCalculations={false}
              showCharts={false}
              disabled={hasMetrics && !CHARTS[metrics[0]?.type].compose}
              {...rest}
            />
          </div>
        )}
      </DroppableZone>
    </Group>
  );
}
