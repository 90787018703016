import cx from 'classnames';
import { Controller } from 'react-hook-form';

import DataMessageInput from 'components/data-message-input';
import Label from 'components/label';
import PhoneNumbersInput from 'components/phone-numbers-input';
import ValidationError from 'components/validation-error';

export default function SmsActionMessageFields({
  configurationField,
  index,
  register,
  errors,
  control,
  watch,
  loading,
}) {
  const enabled = watch(`configurationFields.${index}.enabled`);

  return (
    <div className={cx(['space-y-4', enabled ? 'block' : 'hidden'])}>
      <input type="hidden" {...register(`configurationFields.${index}.id`)} />

      <div className="space-y-2">
        <Label htmlFor={`configurationFields.${index}.phoneNumbers`}>Phone Numbers</Label>
        <Controller
          render={({ field }) => <PhoneNumbersInput disabled={loading} {...field} />}
          name={`configurationFields.${index}.phoneNumbers`}
          defaultValue={configurationField.phoneNumbers}
          control={control}
          rules={{
            validate: value => {
              if (
                enabled &&
                (!value ||
                  !Object.entries(value)?.length ||
                  Object.entries(value).every(([k, v]) => !v?.number))
              ) {
                return 'Please enter a phone number.';
              }

              return true;
            },
          }}
        />
        <ValidationError errors={errors} name={`configurationFields.${index}.phoneNumbers`} />
      </div>

      <div className="space-y-2">
        <Label htmlFor={`configurationFields.${index}.message`}>Message Template</Label>
        <Controller
          render={({ field }) => (
            <DataMessageInput
              rows={4}
              disabled={loading}
              placeholder="Type your custom message and add skill variables from the list below…"
              outputs={configurationField?.outputs}
              {...field}
            />
          )}
          name={`configurationFields.${index}.message`}
          defaultValue={configurationField.message}
          control={control}
          rules={{
            validate: value => {
              if (enabled && value.trim() === '') {
                return 'Please enter a message.';
              }
              return true;
            },
          }}
        />
        <ValidationError errors={errors} name={`configurationFields.${index}.message`} />
      </div>
    </div>
  );
}
