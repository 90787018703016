import { compact, includes, isNil, isString, keys, round, uniq } from 'lodash';
import moment from 'moment';

import { SPACE } from 'modals/chart-details/charts-query-engine/characters';

import {
  DATE_FORMAT_FULL,
  CONSTANTS,
  DIMENSIONS,
  TIME_DIMENSIONS,
  TIME_GROUP_DIMENSIONS,
} from './data';

const helpers = {
  isDimension: key => includes(keys(DIMENSIONS), key),
  isTimeDimension: key => includes(keys(TIME_DIMENSIONS), key),
  isTimeGroupDimension: key => includes(keys(TIME_GROUP_DIMENSIONS), key),
  splitStringViaSymbol: (string, symbol = CONSTANTS.SPLIT_SYMBOL) => {
    if (!isString(string)) return [];
    return string.split(symbol);
  },
  joinArrayViaSymbol: (array, symbol = CONSTANTS.SPLIT_SYMBOL) => {
    if (!Array.isArray(array)) return '';
    return array.join(symbol);
  },
  convertKeyToObject: key =>
    helpers.splitStringViaSymbol(key).reduce((obj, item) => {
      const [, key, value] = helpers.splitStringViaSymbol(item, CONSTANTS.SPLIT_SYMBOL_2);
      if (key) {
        obj[key] = value;
      }
      return obj;
    }, {}),
  isUnknownValue: v => isNil(v) || v === '',
  parseChartValue: value => {
    if (helpers.isUnknownValue(value)) return;
    if (!Number.isNaN(value)) return parseFloat(value);
    if (Array.isArray(value)) return;
    return value;
  },
  parseMetricVariableId: id => helpers.splitStringViaSymbol(id), // [db, table, variable],
  parseVariableName: string => {
    if (!isString(string)) return '';
    if (DIMENSIONS[string]) return DIMENSIONS[string].name;
    const [, , variable] = helpers.parseMetricVariableId(string);
    return variable;
  },
  parseVariableId: string => {
    if (!isString(string)) return '';
    if (DIMENSIONS[string]) return DIMENSIONS[string].type;
    const [, , variable] = helpers.parseMetricVariableId(string);
    return variable;
  },
  trimExtraWhitespace: string => {
    if (!isString(string)) return '';
    return string.replace(/\s+/g, SPACE).trim();
  },
  round: (n, p = 3) => {
    if (Number.isNaN(n)) return;
    return round(n, p);
  },
  convertDateToEpoch: date => {
    if (!date || !moment(date).isValid()) return;
    return moment(date).unix() * 1000000000;
  },
  createMoment: time => {
    if (!time) return;
    const m = moment.utc(time / 1000000);
    if (!m.isValid()) return;
    return m;
  },
  formatTimeGroup(dimension, timeValue, ops) {
    const { useLocal = false, noFormat = false } = ops || {};

    // NOTE: time groups are always in UTC
    const timeDimensionsDef = {
      // TODO: camelCase!!!!
      hour_of_day: { format: 'LT', setter: 'hour' }, // 0-23
      day_of_week: { format: 'dddd', setter: 'day' }, // 0-6
      day_of_month: { format: 'Do', setter: 'date' }, // 1-n
      month_of_year: { format: 'MMMM', setter: 'month', offset: -1 }, // 1-12 needs offset to match moment (timestream is 1-12, moment is 0-11)
      quarter: { format: 'Qo', setter: 'quarter' }, // 1-4
    };
    const def = timeDimensionsDef[dimension];

    const m = moment.utc()[def.setter](parseInt(timeValue, 10) + (def?.offset || 0));

    if (useLocal) m.local();
    m.startOf(def.setter);

    if (noFormat) return m;
    return m.format(def.format);
  },
  // TODO: Move into context provider as a factory function with useUtc predefined
  formatTime: (time, format = DATE_FORMAT_FULL, useUtc = false) => {
    const m = helpers.createMoment(time);
    if (!m) return;
    if (!useUtc) m.local();
    return m.format(format);
  },
  formatNumber: (n, p) => {
    const number = parseFloat(n);
    if (Number.isNaN(number)) return 0;
    return helpers.round(number, p).toLocaleString();
  },
  cleanList: list => {
    if (!Array.isArray(list)) return [];
    return uniq(compact(list));
  },
  getLongestString: list => {
    if (!Array.isArray(list)) return [];
    return list.reduce((a, b) => (a.length > b.length ? a : b), []);
  },
  stringsMatch: (s1, s2) => {
    const trim = s => helpers.trimExtraWhitespace(s?.toLowerCase());
    return trim(s1) === trim(s2);
  },
};

export default helpers;
