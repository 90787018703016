import { forwardRef, useState, useEffect } from 'react';

import { Badge } from '@optra/kit';

import Textarea from 'components/textarea';

function getMatches(string, regex, index = 0) {
  const matches = [];
  let match;
  while ((match = regex.exec(string))) {
    matches.push(match[index]);
  }
  return matches;
}

export default forwardRef(
  ({ value = '', outputs = [], onChange = () => false, ...textAreaProps }, ref) => {
    const [selectedOutputs, setSelectedOutputs] = useState([]);

    const handleAddOutput = output => {
      onChange([value, `$\{${output.output.id}}`].join(' '));
    };

    useEffect(() => {
      const regex = /\$\{(\w+)\}/g;
      const outputIds = getMatches(value, regex, 1);
      const outputObjects = (outputIds || []).map(id => outputs.find(o => o.output.id === id));
      setSelectedOutputs(outputObjects);
    }, [value, outputs]);

    return (
      <>
        <Textarea
          ref={ref}
          {...textAreaProps}
          value={value}
          onChange={onChange}
          className="mb-2 text-sm"
        />
        <div className="flex flex-wrap">
          {outputs.map((output, i) => (
            <Badge
              size="xs"
              variant={selectedOutputs.includes(output) ? 'secondary' : 'muted'}
              key={`${output.output.id}-${output.output.label}-${i}`}
              className="mx-2 my-1 cursor-pointer"
              onClick={() => handleAddOutput(output)}
            >
              {`+ ${output.output.label}`}
            </Badge>
          ))}
        </div>
      </>
    );
  },
);
