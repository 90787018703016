import { api, q } from 'config/api';

export default function useChartConfigMetrics(overrides) {
  return q.useQuery({
    queryKey: ['chartConfigMetrics'],
    queryFn: () =>
      api(
        `query chartConfigMetrics {
          chartConfigMetrics {
            id
            name
            db
            table
          }
        }`,
      ),
    ...overrides,
  });
}
