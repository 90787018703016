import {
  cloneDeep,
  compact,
  filter,
  findIndex,
  flattenDeep,
  includes,
  isBoolean,
  isEqual,
  isEmpty,
  isFunction,
  isObject,
  isString,
  isUndefined,
  max,
  min,
  some,
  uniqBy,
  xor,
} from 'lodash';
import moment from 'moment';
import { createContext, useReducer, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useInterval } from 'react-use';

import { useFeature } from 'components/feature';
import { api, q } from 'config/api';
import { useHasRoles } from 'hooks';
import { SPACE, COMMA } from 'modals/chart-details/charts-query-engine/characters';
import { useChart, useChartPreview, useChartConfigMetrics, useChartTableMetrics } from 'queries';

import {
  CHARTS,
  DATE_FORMAT,
  DATE_FORMAT_FULL,
  TIMEFRAMES,
  TIMEFRAME_BINS,
  SMART_TIMEFRAMES,
  CONSTANTS,
  INTERVAL_LITERALS,
  TIME_DIMENSIONS,
  TIME_GROUP_DIMENSIONS,
  GENERAL_DIMENSIONS,
  DIMENSIONS,
  DIMENSION_ALIASES,
  CALCULATIONS,
  VARIABLE_STATUS,
  QUERY_OVERRIDES,
  DEFAULT_METRIC,
  INTERPOLATION_STYLES,
} from '../data';
import _helpers from '../helpers';
import useChartSql from '../use-chart-sql';

const ChartContext = createContext();
const useChartCtx = () => useContext(ChartContext);

///////////////////////
// STATE
///////////////////////

const INIT_STATE = {
  chart: {
    config: {},
  },
};

const reducer = (state, { type, payload }) => {
  const { chart = {} } = state;
  switch (type) {
    case 'resetState':
      return INIT_STATE;
    case 'setState':
      return { ...state, ...payload };
    case 'setChart':
      return {
        ...state,
        chart: {
          ...chart,
          ...payload,
          config: {
            ...chart.config,
            ...payload.config,
          },
        },
      };
    default:
      return state;
  }
};

function ChartProvider(props) {
  const { chartId: overrideChartId, configuration: _configuration, children } = props;
  const configuration = {
    inModal: true,
    showInterface: true,
    preserveXAxis: true,
    ..._configuration,
  };

  const [readOnly, loadingRoles] = useHasRoles(['chartViewerOnly'], true);
  const allSkillOutputsEnabled = useFeature('allSkillOutputs', 'global');

  const navigate = useNavigate();
  const { workspaceId, chartId: paramChartId } = useParams();

  const chartId = overrideChartId || paramChartId;

  const [state, dispatch] = useReducer(reducer, INIT_STATE);

  /////////////
  // QUERIES //
  /////////////

  // TODO: Combine queries
  const chartQuery = useChart(chartId, QUERY_OVERRIDES);
  const chartConfigMetricsQuery = useChartConfigMetrics();
  const chartTableMetricsQuery = useChartTableMetrics();

  // TODO: Rename to chartData, this should resolve based on configuration state and should be sourced from a single state
  // this is the source of all evil
  const dynamicQuery = state.chart?.query || chartQuery?.data?.chart?.query;
  const chartPreviewQuery = useChartPreview(dynamicQuery, QUERY_OVERRIDES);

  const queries = {
    chart: chartQuery,
    chartConfigMetrics: chartConfigMetricsQuery,
    chartTableMetrics: chartTableMetricsQuery,
    chartPreview: chartPreviewQuery,
  };

  ///////////////
  // MUTATIONS //
  ///////////////

  const qc = q.useQueryClient();

  const mutations = {
    updateChart: q.useMutation({
      mutationFn: form =>
        api(
          `mutation updateChart($form: updateChartForm!) {
            updateChart(form: $form) {
              id
            }
          }`,
          { form },
        ),
      onSettled: () => {
        qc.invalidateQueries({ queryKey: ['chart'] });
        qc.invalidateQueries({ queryKey: ['workspace'] });
      },
    }),
    removeChart: q.useMutation({
      mutationFn: id =>
        api(
          `mutation removeChart($id: ID!) {
            removeChart(id: $id) {
              id
            }
          }`,
          { id },
        ),
      onSettled: () => qc.invalidateQueries({ queryKey: ['workspace'] }),
    }),
    duplicateChart: q.useMutation({
      mutationFn: form =>
        api(
          `mutation duplicateChart($form: duplicateChartForm!) {
            chart: duplicateChart(form: $form) {
              id
            }
          }`,
          { form },
        ),
      async onMutate(form) {
        await qc.cancelQueries({ queryKey: ['workspace', form.targetWorkspaceId] });
        await qc.resetQueries({
          queryKey: ['workspace', form.targetWorkspaceId],
          exact: true,
          cancelRefetch: true,
        });
      },
      onSettled(r, err, form) {
        qc.invalidateQueries({ queryKey: ['workspace', form.targetWorkspaceId] });
      },
    }),
  };

  /////////////
  // HELPERS //
  /////////////

  const dataHelpers = {
    getRawQueryData: query => queries[query]?.data?.[query],
    getInitChart: () => {
      const chart = dataHelpers.getRawQueryData('chart');

      if (!chart) return {};

      let deprecatedMetric;

      if (chart.config.calculationConfig) {
        let shapedMetricData = {
          metric: chart.config.calculationConfig,
          type: chart.type,
          calculation: chart.config.calculation,
        };
        // rely on spreading here to intentionally ignore undefined fields
        deprecatedMetric = {
          ...DEFAULT_METRIC,
          ...shapedMetricData,
        };
      }

      // backwards compatibility for the switch from single metric to multi metric
      // (calculationConfig => metrics[0])
      chart.config.metrics = !!(Array.isArray(chart.config.metrics) && chart.config.metrics?.length)
        ? chart.config.metrics
        : _helpers.cleanList([deprecatedMetric]);

      // cleanup deprecated fields for rendering
      // (actual cleanup occurs on next update and save)
      chart.type = null;
      chart.config.calculation = null;
      chart.config.calculationConfig = null;

      return chart;
    },
    getChart: () => {
      const initChart = dataHelpers.getInitChart();
      const chartPreview = dataHelpers.getRawQueryData('chartPreview');
      const chart = dataHelpers.deepMergeChart(initChart, state.chart);
      chart.queryResult = chartPreview?.queryResult;
      return chart;
    },
    getChartConfig: () => dataHelpers.getChart()?.config || {},
    getChartConfigMetrics: () => {
      const configMetrics = dataHelpers.getRawQueryData('chartConfigMetrics') || [];
      return uniqBy(configMetrics, 'id');
    },
    getChartTableMetrics: () => dataHelpers.getRawQueryData('chartTableMetrics'),
    deepMergeChart: (chartA, chartB) => {
      if (!chartA) return;
      if (!chartB) return chartA;
      return {
        ...chartA,
        ...chartB,
        config: {
          ...chartA?.config,
          ...chartB?.config,
        },
      };
    },
    isMetricTablePopulated: metricId => some(dataHelpers.getChartTableMetrics(), ['id', metricId]),
    getAxisVariables: config => {
      const { xAxis, yAxis } = config || dataHelpers.getChartConfig();
      const variables = [...(xAxis || []), ...(yAxis || [])];
      return _helpers.cleanList(variables);
    },
    getMetricVariables: config => {
      const { metrics = [] } = config || dataHelpers.getChartConfig();
      if (!metrics?.length) return [];
      return metrics.map(({ metric }) => metric);
    },
    getUsedVariables: config => {
      const variables = [
        ...dataHelpers.getMetricVariables(config),
        ...dataHelpers.getAxisVariables(config),
      ];
      return _helpers.cleanList(variables);
    },
    getFilters: () => {
      const { deviceIds, workflowIds, skillIds, tagIds, locationIds } =
        dataHelpers.getChartConfig();
      return _helpers.cleanList([
        ...(deviceIds || []),
        ...(workflowIds || []),
        ...(skillIds || []),
        ...(tagIds || []),
        ...(locationIds || []),
      ]);
    },
    getCurrentTableFromConfig: config => {
      const variables = dataHelpers.getUsedVariables(config);
      const firstVariableWithTable = variables?.find?.(variable => {
        const [, table] = _helpers.parseMetricVariableId(variable);
        return !!table;
      });
      return _helpers.parseMetricVariableId(firstVariableWithTable);
    },
    getTimeKey: config => {
      const { syncTimezones } = config || dataHelpers.getChartConfig();
      return syncTimezones ? TIME_DIMENSIONS.tzTime.type : TIME_DIMENSIONS.time.type;
    },
    variableTableMatchesConfig: id => {
      const [db, table] = _helpers.parseMetricVariableId(id);
      const [activeDB, activeTable] = dataHelpers.getCurrentTableFromConfig();
      if (table && activeTable && `${db}.${table}` !== `${activeDB}.${activeTable}`) return false;
      return true;
    },
    getVariableStatus: id => {
      if (DIMENSIONS?.find?.(dim => dim.type === id)) {
        return VARIABLE_STATUS.valid;
      }
      const availableMetrics = dataHelpers.getChartConfigMetrics();
      if (!availableMetrics) {
        return VARIABLE_STATUS.loading;
      }
      const matchingMetric = availableMetrics?.find?.(metric => metric.id === id);
      if (matchingMetric) {
        if (!matchingMetric.table) {
          return VARIABLE_STATUS.pending;
        }
        return VARIABLE_STATUS.valid;
      }
      return VARIABLE_STATUS.error;
    },
    hasUnsavedChanges: () => {
      const initChart = dataHelpers.getInitChart() || {};
      const chart = dataHelpers.getChart() || {};

      const generateMetricString = objectArray =>
        objectArray?.map(({ metric, calculation, type, color }) => {
          return _helpers.cleanList([metric, calculation, type, color]).join('');
        });

      const initialState = {
        ...initChart,
        query: undefined,
        queryResult: undefined,
        config: {
          ...(initChart.config || {}),
          metrics: generateMetricString(initChart.config?.metrics),
        },
      };

      const updateState = {
        ...chart,
        query: undefined,
        queryResult: undefined,
        config: {
          ...(chart.config || {}),
          metrics: generateMetricString(chart.config?.metrics),
        },
      };

      const matchingCharts = isEqual(initialState, updateState);
      const matchingQueries = _helpers.stringsMatch(chart?.query, initChart?.query);

      return !matchingCharts || !matchingQueries;
    },
  };

  const ruleHelpers = {
    allowsFrequency: () =>
      !!filter(
        dataHelpers.getAxisVariables(),
        key => !!TIME_DIMENSIONS[key] && TIME_DIMENSIONS[key].type === TIME_DIMENSIONS.time.type,
      ).length,
    allowsDiscardZeros: () =>
      !!filter(dataHelpers.getAxisVariables(), key => _helpers.isTimeDimension(key)).length,
    allowsInterpolation: (chart = {}) => {
      const currentChart = dataHelpers.deepMergeChart(dataHelpers.getChart(), chart);
      const {
        type,
        config: { metrics = [], xAxis = [], timeframeBin, syncTimezones },
      } = currentChart;

      const isSingleMetric = metrics?.length <= 1;
      const xAxisIsTimeOnly = xAxis?.length === 1 && xAxis[0] === TIME_DIMENSIONS.time.type;
      const isMonthGranularity =
        TIMEFRAME_BINS[timeframeBin]?.type === TIMEFRAME_BINS['1month'].type;

      // disable interpolation for table chart type for now,
      // since it does not explicitly use the interpolated version of the base query
      const isValidChartType = type !== CHARTS.columns.type;
      return !!(
        isSingleMetric &&
        !syncTimezones &&
        xAxisIsTimeOnly &&
        !isMonthGranularity &&
        isValidChartType
      );
    },
  };

  const helpers = {
    ...dataHelpers,
    ...ruleHelpers,
    ..._helpers,
  };

  /////////////
  // METHODS //
  /////////////

  const {
    generateSelectStatement,
    createTimeframeCondition,
    createArrayCondition,
    createNoNullValueCondition,
    createBooleanCondition,
    generateInterpolationQuery,
    createAlias,
    timeExtract,
    timeTrunc,
    formatClauseValues,
  } = useChartSql({ chartConfig: helpers.getChartConfig() });

  const methods = {
    parseQueryResult: queryResult => {
      const formattedData = {
        timestamps: {
          list: [],
          min: undefined,
          max: undefined,
        },
        variables: [],
        data: [],
        values: {
          metrics: {},
        },
      };

      if (!queryResult) return formattedData;

      const { metrics: _metrics } = helpers.getChartConfig();

      const metrics = _metrics?.map(metric => {
        const [db, table, variable] = helpers.parseMetricVariableId(metric.metric);
        return {
          ...metric,
          id: metric.metric,
          table: `${db}.${table}`,
          variable,
        };
      });

      formattedData.data = queryResult;
      formattedData.variables = Object.keys(formattedData.data[0] || {});

      metrics?.forEach(m => {
        const { variable, calculation: _calculation } = m;
        const calculation = CALCULATIONS[_calculation];
        formattedData.values.metrics[variable] = {};
        formattedData.values.metrics[variable].list = queryResult.map(datum => datum[variable]);
        formattedData.values.metrics[variable].total = helpers.round(
          calculation?.totalResolver?.(formattedData.values.metrics[variable].list) ?? 0,
        );
      });

      formattedData.timestamps.list = formattedData.data.map(datum => datum?.time);
      formattedData.timestamps.min = !!formattedData.timestamps.list?.length
        ? min(formattedData.timestamps.list)
        : undefined;
      formattedData.timestamps.max = !!formattedData.timestamps.list?.length
        ? max(formattedData.timestamps.list)
        : undefined;

      return formattedData;
    },
    generateChartQueryData: ({ chart: _chart = {}, options = {} }) => {
      const chart = helpers.deepMergeChart(helpers.getChart(), _chart);

      const { config = {} } = chart;
      const { allowNullValue = false } = options;
      const {
        metrics,
        timeframe,
        timeframeBin,
        deviceIds,
        workflowIds,
        skillIds,
        tagIds,
        locationIds,
        syncTimezones,
        disabledDays,
        disabledHours,
        discardZeros,
      } = config;

      const [db, table] = helpers.getCurrentTableFromConfig(config);

      const allMetricsReady = metrics?.every(
        ({ metric, calculation }) =>
          !!(CALCULATIONS[calculation] && helpers.isMetricTablePopulated(metric)),
      );

      if (!allMetricsReady || !table) return '';

      const columns = helpers.getAxisVariables(config).map(helpers.parseVariableId);

      const timeKey = helpers.getTimeKey(config);

      ///////////////////
      // BUILDING THE QUERY

      const SELECT = {
        values: helpers.cleanList([
          ...metrics?.map(metric => {
            return CALCULATIONS?.[metric.calculation]?.resolver?.(metric);
          }),
          ...columns.map(column => {
            if (helpers.isTimeGroupDimension(column)) {
              return createAlias({
                text: timeExtract({
                  key: timeKey,
                  extract: TIME_GROUP_DIMENSIONS[column].extract,
                }),
                alias: column,
              });
            }
            if (helpers.isTimeDimension(column)) {
              return createAlias({
                text: timeTrunc({
                  key: timeKey,
                  interval_literal: timeframeBin,
                }),
                alias: column,
              });
            }
            return column;
          }),
        ]),
      };

      // TODO: We can unlock multi-table lookups by simply wrapping this logic in select and using table data from metrics
      const FROM = `"${db}"."${table}"`;

      const timeframeStatement = createTimeframeCondition({
        key: timeKey,
        ...timeframe,
        db,
        table,
      });

      const WHERE = {
        values: helpers.cleanList([
          !allowNullValue &&
            createNoNullValueCondition({
              value: metrics.map(({ metric }) => helpers.parseVariableId(metric)),
            }),
          !!discardZeros &&
            `(${metrics
              .map(m => {
                const metric = helpers.parseVariableId(m.metric);
                return `(${metric}${SPACE}>${SPACE}0${SPACE}OR${SPACE}${metric}${SPACE}<${SPACE}0)`;
              })
              .join(`${SPACE}AND${SPACE}`)})`,
          !!syncTimezones &&
            createBooleanCondition({
              key: 'tzExists',
              value: true,
            }),
          timeframeStatement,
          createArrayCondition(
            {
              key: `extract(${TIME_GROUP_DIMENSIONS.day_of_week.extract} FROM ${timeKey})`,
              value: disabledDays,
            },
            { inverseCondition: true, isString: false },
          ),
          createArrayCondition(
            {
              key: `extract(${TIME_GROUP_DIMENSIONS.hour_of_day.extract} FROM ${timeKey})`,
              value: disabledHours,
            },
            { inverseCondition: true, isString: false },
          ),
          createArrayCondition({ key: DIMENSIONS.device_id.type, value: deviceIds }),
          createArrayCondition({ key: DIMENSIONS.workflow_id.type, value: workflowIds }),
          createArrayCondition({ key: DIMENSIONS.skill_id.type, value: skillIds }),
          createArrayCondition({ key: DIMENSIONS.location_id.type, value: locationIds }),
          !isEmpty(tagIds)
            ? `(${formatClauseValues(
                tagIds.map(tagId => `"tags.${tagId}" = true`),
                { newLineFirst: false, newLines: false, join: `${SPACE}OR${SPACE}` },
              )})`
            : undefined,
        ]),
      };

      const GROUP_BY = {
        values: helpers.cleanList([
          ...columns.map(column => {
            if (helpers.isTimeGroupDimension(column)) {
              return timeExtract({ key: timeKey, extract: TIME_DIMENSIONS[column].extract });
            }
            if (helpers.isTimeDimension(column)) {
              return timeTrunc({ key: timeKey, interval_literal: timeframeBin });
            }
            return column;
          }),
        ]),
      };

      const ORDER_BY = { values: helpers.cleanList(columns), options: { direction: 'ASC' } };

      return {
        SELECT,
        FROM,
        WHERE,
        GROUP_BY,
        ORDER_BY,
      };
    },
    generateChartQueryString: ({ chart: _chart }) => {
      const chart = helpers.deepMergeChart(helpers.getChart(), _chart);

      const { config = {} } = chart;
      const {
        interpolate,
        metrics: _metrics,
        timeframeBin,
        interpolationStyle,
        interpolationFill,
        discardZeros,
      } = config;

      if (!_metrics?.length) return;

      const metrics = _metrics.map(({ metric }) => helpers.parseVariableId(metric));

      const isInterpolated = interpolate && helpers.allowsInterpolation(chart);
      const chartQueryData = methods.generateChartQueryData({ chart });

      if (isInterpolated) {
        return generateInterpolationQuery({
          chartQueryData,
          metric: metrics[0],
          interval_literal: timeframeBin,
          interpolationStyle,
          interpolationFill,
          discardZeros,
        });
      }

      return generateSelectStatement(chartQueryData);
    },
    generateNameFromConfig: config => {
      const { includePeriod, includePeriodBin } = config || {};
      const { metrics, xAxis, yAxis, timeframe, timeframeBin } = helpers.getChartConfig();

      if (!metrics || !metrics[0]) return '';

      const METRICS_STRING = metrics
        .map(({ metric, calculation }) => {
          const CALC_STRING = !!CALCULATIONS[calculation]
            ? `${CALCULATIONS[calculation].name} of`
            : undefined;

          const METRIC_STRING = !!helpers.parseVariableName(metric)
            ? `${helpers.parseVariableName(metric)}`
            : undefined;
          return helpers.cleanList([CALC_STRING, METRIC_STRING]).join(SPACE);
        })
        .join(COMMA);

      const VS_STRING = !!xAxis?.length
        ? `over ${xAxis.map(helpers.parseVariableName).join('/')}`
        : undefined;

      const GROUPED_BY_STRING = !!yAxis?.length
        ? `grouped by ${yAxis.map(helpers.parseVariableName).join('/')}`
        : undefined;

      const AXIS_STRING = helpers.cleanList([METRICS_STRING, VS_STRING]).join(SPACE);

      if (!AXIS_STRING) return '';

      const CHART_NAME = [AXIS_STRING, GROUPED_BY_STRING];

      !!(includePeriodBin && helpers.allowsFrequency()) &&
        CHART_NAME.push(TIMEFRAME_BINS[timeframeBin]?.name);

      !!includePeriod &&
        CHART_NAME.push({ ...SMART_TIMEFRAMES, ...TIMEFRAMES }[timeframe?.start]?.name);

      return helpers.cleanList(CHART_NAME).join(COMMA);
    },
  };

  /////////////
  // ACTIONS //
  /////////////

  const chartActions = {
    saveChart: () => {
      if (readOnly) return;
      const chart = helpers.getChart() || {};
      const { id, name, query, type, config, isCustomQuery } = chart;
      if (!id) return;

      mutations.updateChart.mutate({
        id,
        name,
        query,
        isCustomQuery,
        type,
        config,
      });
    },
    updateChart: _chart => {
      if (!_chart) return;
      const chart = helpers.getChart() || {};
      const nextChart = helpers.deepMergeChart(chart, _chart);
      nextChart.query = _chart.query || methods.generateChartQueryString({ chart: nextChart });
      nextChart.isCustomQuery = !_helpers.stringsMatch(
        nextChart.query,
        methods.generateChartQueryString({ chart: nextChart }),
      );
      dispatch({
        type: 'setChart',
        payload: nextChart,
      });
    },
    resetChart: () => {
      const initChart = helpers.getInitChart();
      if (!initChart?.id) return;
      chartActions.updateChart(initChart);
    },
    removeChart: () => {
      if (readOnly) return;
      if (window.confirm('Are you sure you want to remove this chart?')) {
        mutations.removeChart.mutate(chartId, {
          onSuccess: () => navigate(`/charts/${workspaceId}`),
        });
      }
    },
    duplicateChart: (form, ctx) => {
      if (readOnly) return;
      mutations.duplicateChart.mutate(form, {
        onSuccess: () => {
          ctx.setShowDuplicateModal(false);
          navigate(`/charts/${form.targetWorkspaceId}`);
        },
      });
    },
    // QUERY STRING
    setQuery: query => chartActions.updateChart({ query }),
  };

  const chartConfigActions = {
    updateChartConfig: config => {
      chartActions.updateChart({ config });
    },
    // TIMEFRAME
    setTimeframe: ({ start, end }) => {
      if (!start) return;
      if (TIMEFRAMES[start] || SMART_TIMEFRAMES[start]) {
        chartConfigActions.updateChartConfig({
          timeframe: {
            start,
            end: null,
          },
        });
        return;
      }
      const startDate = _helpers
        .convertDateToEpoch(moment(start).startOf('day').toDate())
        ?.toString();
      // automatically defaults to today if no `end` selected
      const endDate = _helpers.convertDateToEpoch(moment(end).endOf('day').toDate())?.toString();
      chartConfigActions.updateChartConfig({
        timeframe: {
          start: startDate,
          end: endDate,
        },
      });
    },
    // TIMEFRAME BIN (GRANULARITY)
    setTimeframeBin: value => {
      const timeframeBin = TIMEFRAME_BINS[value];
      if (!timeframeBin) {
        return;
      }
      const { type } = timeframeBin;
      chartConfigActions.updateChartConfig({
        timeframeBin: type,
      });
    },
    // DISCARD ZEROS
    setDiscardZeros: value =>
      chartConfigActions.updateChartConfig({
        discardZeros: !!value,
      }),
    // INTERPOLATION
    setInterpolation: value =>
      chartConfigActions.updateChartConfig({
        interpolate: !!value,
      }),
    setInterpolationStyle: value => {
      const interpolationStyle =
        INTERPOLATION_STYLES[value] || INTERPOLATION_STYLES.interpolate_fill;
      const { interpolationFill: _interpolationFill } = helpers.getChartConfig();
      const interpolationFill =
        interpolationStyle.type !== INTERPOLATION_STYLES.interpolate_fill.type
          ? null
          : _interpolationFill;
      chartConfigActions.updateChartConfig({
        interpolationStyle: interpolationStyle.type,
        interpolationFill,
      });
    },
    setInterpolationFill: value => {
      const interpolationFill = value && parseFloat(value);
      if (Number.isNaN(interpolationFill)) return;
      chartConfigActions.updateChartConfig({
        interpolationFill,
      });
    },
    // SYNC TIMEZONES
    setSyncTimezones: value =>
      chartConfigActions.updateChartConfig({
        syncTimezones: !!value,
      }),
    // METRICS
    setMetrics: _metrics => {
      if (!Array.isArray(_metrics)) return;

      const validMetrics = (
        allSkillOutputsEnabled ? helpers.getChartTableMetrics() : helpers.getChartConfigMetrics()
      )?.map(({ id }) => id);

      if (!Array.isArray(validMetrics) || !validMetrics?.length) return;

      const metrics = uniqBy(
        [
          ...compact(
            _metrics.map(m => {
              if (!m?.metric) return undefined;
              if (!includes(validMetrics, m.metric)) return undefined;
              return {
                metric: m.metric || DEFAULT_METRIC.metric,
                type: m.type || DEFAULT_METRIC.type,
                calculation: m.calculation || DEFAULT_METRIC.calculation,
                color: m.color,
              };
            }),
          ),
        ],
        'metric',
      );

      const payload = {
        type: null,
        config: {
          metrics,
          calculationConfig: null,
          calculation: null,
        },
      };

      // the actual cleanup for deprecated fields
      chartActions.updateChart(payload);
    },
    addMetric: _metric => {
      if (!_metric) return;
      const metric = Array.isArray(_metric) ? _metric : [_metric];
      const { metrics = [] } = helpers.getChartConfig();
      const newMetrics = flattenDeep([...metrics, metric]);
      chartConfigActions.setMetrics(newMetrics);
    },
    updateMetric: (metric, data) => {
      if (!metric || !isObject(metric) || !data || !isObject(data)) return;
      const { metrics = [] } = helpers.getChartConfig();
      const newMetrics = cloneDeep(metrics);
      let index = findIndex(newMetrics, metric);
      if (index < 0) return;
      newMetrics.splice(index, 1, { ...metric, ...data });
      chartConfigActions.setMetrics(newMetrics);
    },
    removeMetric: metric => {
      if (!metric) return;
      let metricId;
      if (isString(metric)) metricId = metric;
      if (isObject(metric)) metricId = metric.metric;
      if (!metricId) return;
      const { metrics = [] } = helpers.getChartConfig();
      const newMetrics = (metrics || []).filter(m => m.metric !== metricId);
      chartConfigActions.setMetrics(newMetrics);
    },
    // VARIABLES / AXIS
    setVariables: (variables, field) => {
      if (!Array.isArray(variables) || !field) return;
      const list = helpers.cleanList(variables);
      chartConfigActions.updateChartConfig({
        [field]: list,
      });
    },
    addVariable: (variable, field) => {
      const chartConfig = helpers.getChartConfig();
      const currentList = chartConfig[field] || [];
      const list = [...currentList];
      list.push(variable);
      chartConfigActions.setVariables(list, field);
    },
    replaceVariable: (oldVariable, newVariable, field) => {
      if (!oldVariable || !newVariable) return;
      const chartConfig = helpers.getChartConfig();
      const currentList = chartConfig[field] || [];
      if (includes(currentList, newVariable)) return;
      const list = xor([...currentList], [oldVariable, newVariable]);
      chartConfigActions.setVariables(list, field);
    },
    removeVariable: (variable, field) => {
      const chartConfig = helpers.getChartConfig();
      const currentList = chartConfig[field] || [];
      const list = filter(currentList, v => v !== variable);
      chartConfigActions.setVariables(list, field);
    },
    reorderArray: (startIndex, endIndex, field) => {
      if (isUndefined(startIndex) || isUndefined(endIndex)) return;
      const chartConfig = helpers.getChartConfig();
      const currentList = chartConfig[field] || [];
      if (!currentList.length) return;
      if (endIndex >= currentList.length) return;
      const list = [...currentList];
      const [removed] = list.splice(startIndex, 1);
      list.splice(endIndex, 0, removed);
      chartConfigActions.setVariables(list, field);
    },
    handleOnDragEnd: result => {
      if (!result) return;

      const { source, destination } = result;

      // invalid drop
      if (!destination) return;

      // tried to change list
      if (source.droppableId !== destination.droppableId) return;

      // did not change placement
      if (source.index === destination.index) return;

      chartConfigActions.reorderArray(source.index, destination.index, source.droppableId);
    },
    // DEVICES
    setDevices: deviceIds => {
      chartConfigActions.updateChartConfig({
        deviceIds: !!deviceIds?.length ? deviceIds : null,
      });
    },
    clearDevices: () => chartConfigActions.setDevices(),
    toggleDevices: device_id => {
      const { deviceIds } = helpers.getChartConfig();
      const newDeviceIds = xor(deviceIds, [device_id]);
      chartConfigActions.setDevices(newDeviceIds);
    },
    // WORKFLOWS
    setWorkflows: workflowIds => {
      chartConfigActions.updateChartConfig({
        workflowIds: !!workflowIds?.length ? workflowIds : null,
      });
    },
    clearWorkflows: () => chartConfigActions.setWorkflows(),
    toggleWorkflows: workflow_id => {
      const { workflowIds } = helpers.getChartConfig();
      const newWorkflowIds = xor(workflowIds, [workflow_id]);
      chartConfigActions.setWorkflows(newWorkflowIds);
    },
    // SKILLS
    setSkills: skillIds => {
      chartConfigActions.updateChartConfig({
        skillIds: !!skillIds?.length ? skillIds : null,
      });
    },
    clearSkills: () => chartConfigActions.setSkills(),
    toggleSkills: skill_id => {
      const { skillIds } = helpers.getChartConfig();
      const newSkillIds = xor(skillIds, [skill_id]);
      chartConfigActions.setSkills(newSkillIds);
    },
    // TAGS
    clearTags: () => {
      chartConfigActions.updateChartConfig({
        tagIds: null,
      });
    },
    toggleTags: tag => {
      const { tagIds: currentTagsIds } = helpers.getChartConfig();
      const nextTagsIds = xor(currentTagsIds, [tag.name]);
      chartConfigActions.updateChartConfig({
        tagIds: nextTagsIds,
      });
    },
    // LOCATIONS
    setLocations: locationIds => {
      chartConfigActions.updateChartConfig({
        locationIds: !!locationIds?.length ? locationIds : null,
      });
    },
    clearLocations: () => chartConfigActions.setLocations(),
    toggleLocations: location_id => {
      const { locationIds } = helpers.getChartConfig();
      const newLocationIds = xor(locationIds, [location_id]);
      chartConfigActions.setLocations(newLocationIds);
    },
    // DISABLED DAYS
    setDisabledDays: disabledDays => {
      chartConfigActions.updateChartConfig({
        disabledDays: !!disabledDays?.length ? disabledDays : null,
      });
    },
    toggleDisabledDays: day => {
      const { disabledDays } = helpers.getChartConfig();
      const newDisabledDays = xor(disabledDays, [day]);
      chartConfigActions.setDisabledDays(newDisabledDays);
    },
    // DISABLED HOUR
    setDisabledHours: disabledHours => {
      chartConfigActions.updateChartConfig({
        disabledHours: !!disabledHours?.length ? disabledHours : null,
      });
    },
    toggleDisabledHours: hour => {
      const { disabledHours } = helpers.getChartConfig();
      const newDisabledHours = xor(disabledHours, [hour]);
      chartConfigActions.setDisabledHours(newDisabledHours);
    },
  };

  const actions = {
    ...chartActions,
    ...chartConfigActions,
    dispatch,
  };

  const chart = helpers.getChart();
  const { id, config, queryResult, query } = chart || {};
  const { metrics = [], timeframe, xAxis = [] } = config || {};

  // NOTE: did not end up using internal refetcher for now to be able to account for mutated configurations
  const shouldRefetch = config?.timeframe?.start === 'latest';
  useInterval(
    () => {
      chartQuery.refetch();
      chartPreviewQuery.refetch();
    },
    shouldRefetch ? 60_000 : null,
  );

  const filterDefinedQueryData = methods.generateChartQueryData({ chart });
  const filterDefinedQueryString = methods.generateChartQueryString({ chart });

  const parsedQueryResults = methods.parseQueryResult(queryResult);

  const loadingChartData = queries.chart.isLoading || queries.chartPreview.isLoading;

  const loading = !!(loadingRoles || loadingChartData);
  const chartInitialized = !!(id && metrics?.length && timeframe?.start);
  const saving = !!(mutations.updateChart.isPending || mutations.removeChart.isPending);

  const calculatedState = {
    loading,
    chartInitialized,
    saving,
    parsedQueryResults,
    filterDefinedQueryData,
    filterDefinedQueryString,
    // Temporary fallback to support old logic
    isCustomQuery: isBoolean(chart.isCustomQuery)
      ? chart.isCustomQuery
      : !_helpers.stringsMatch(query, filterDefinedQueryString),
    interpolatingTimeGroupKeys: filter(xAxis, helpers.isTimeGroupDimension),
    unsavedChanges: helpers.hasUnsavedChanges(),
    showFrequency: helpers.allowsFrequency(),
    showInterpolate: helpers.allowsInterpolation(),
    showDiscardZeros: helpers.allowsDiscardZeros(),
  };

  const constants = {
    CHARTS,
    CALCULATIONS,
    INTERVAL_LITERALS,
    TIME_GROUP_DIMENSIONS,
    TIME_DIMENSIONS,
    GENERAL_DIMENSIONS,
    DIMENSIONS,
    DIMENSION_ALIASES,
    DATE_FORMAT,
    DATE_FORMAT_FULL,
    TIMEFRAMES,
    TIMEFRAME_BINS,
    SMART_TIMEFRAMES,
    CONSTANTS,
    VARIABLE_STATUS,
    QUERY_OVERRIDES,
    DEFAULT_METRIC,
    INTERPOLATION_STYLES,
  };

  const value = {
    state: {
      ...state,
      ...calculatedState,
    },
    configuration: {
      ...configuration,
      readOnly,
    },
    queries,
    mutations,
    helpers,
    methods,
    actions,
    ...constants,
  };

  return (
    <ChartContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </ChartContext.Provider>
  );
}

export { ChartContext, ChartProvider, useChartCtx };
