import { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Card, Button } from '@optra/kit';

import Message from 'components/message';
import Modal from 'components/modal';
import ModalBody from 'components/modal-body';
import ModalFooter from 'components/modal-footer';
import ModalInner from 'components/modal-inner';
import ModalTitle from 'components/modal-title';
import SQLInput from 'components/sql-input';
import Table from 'components/table';
import TBody from 'components/tbody';
import TD from 'components/td';
import TH from 'components/th';
import THead from 'components/thead';
import TR from 'components/tr';
import { api, q, useOnSuccess } from 'config/api';
import { useWorkflowAction } from 'queries';

export default function TimestreamActionQuery() {
  const { workflowActionId } = useParams();
  const modal = useRef();
  const [query, setQuery] = useState('');

  const { data, isLoading, isSuccess } = useWorkflowAction(workflowActionId, {
    refetchInterval: 60000,
  });
  const isLoadingDefaults = isLoading;

  useOnSuccess(
    () => {
      const workflowAction = data.workflowAction;
      setQuery(
        workflowAction?.configuration?.eagerQuery?.query ||
          `select * from "${workflowAction?.configuration?.shared?.dbName || '<dbName>'}"."${
            workflowAction?.configuration?.shared?.tableName || '<tableName>'
          }" where time between ago(15m) and now() order by time desc limit 10`,
      );
    },
    { isSuccess },
    [data.workflowAction],
  );

  const Query = q.useQuery({
    enabled: false,
    placeholderData: q.keepPreviousData,
    retry: false,
    queryKey: ['runTimestreamQuery', { workflowActionId, query }],
    queryFn: () =>
      api(
        `query runTimestreamQuery ($id: ID!, $fn: String!, $props: JSON) {
          result: runWorkflowActionQuery(id: $id, fn: $fn, props: $props)
        }`,
        {
          id: workflowActionId,
          fn: 'runQuery',
          props: { query },
        },
      ),
  });

  const configuration = data?.workflowAction?.configuration;
  const resultsHeaderRow = Query.data?.result?.columnInfo || [];
  const resultsRows = Query.data?.result?.rows || [];

  return (
    <Modal ref={modal}>
      <ModalInner>
        <ModalTitle title="Run SQL Query" icon="MagnifyingGlass" loading={isLoadingDefaults} />
        <ModalBody size="lg" className="space-y-4">
          {Query.error && (
            <Message variant="danger" title="Couldn't run command">
              {Query.error.message}
            </Message>
          )}

          <Card
            variant="secondary"
            className="flex items-center justify-between space-x-2 overflow-hidden"
            noPadding
          >
            <div className="h-40 w-full">
              <SQLInput
                onChange={setQuery}
                value={query}
                completions={{ table: configuration.availableTableNames }}
              />
            </div>
          </Card>

          <Card
            variant="secondary"
            className="flex items-center justify-between space-x-2 overflow-hidden"
            noPadding
          >
            <Table compact={false} className={isLoading ? 'animate-pulse' : ''}>
              <THead>
                {resultsHeaderRow.length > 0 && (
                  <TR hover={false}>
                    {resultsHeaderRow?.map?.(({ name }) => (
                      <TH key={name} variant="default">
                        {name}
                      </TH>
                    ))}
                  </TR>
                )}
              </THead>
              <TBody>
                {resultsRows.map((row, idx) => (
                  <TR key={idx}>
                    {row.data.map((datum, idx) => {
                      if (datum.timeSeriesValue) {
                        <TD key={idx}>
                          {datum.timeSeriesValue.map(({ time }) => time).join(', ')}
                        </TD>;
                      }
                      if (datum.nullValue) {
                        return <TD key={idx}>NULL</TD>;
                      }
                      return <TD key={idx}>{datum.scalarValue}</TD>;
                    })}
                  </TR>
                ))}
              </TBody>
            </Table>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => Query.refetch()}
            size="xl"
            disabled={isLoadingDefaults}
            loading={Query.isFetching}
          >
            Run Query
          </Button>
        </ModalFooter>
      </ModalInner>
    </Modal>
  );
}
